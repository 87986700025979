<script setup>
    import moment from 'moment'
    import store from '../store'
    import {onMounted,ref,computed } from 'vue'
    import Fetch from '../classes/Fetch'
    import Cookie from '../classes/Cookie'
    import DateTime from '../classes/DateTime';
    import momentLang from '../classes/momentLang'
    import Header from "./Header.vue";
    import App from '../classes/App'
    import { useRoute,useRouter } from 'vue-router';


        var token=Cookie.getCookie("token"),devices=ref([]),page="admin",route = useRoute(),router= useRouter(),isMobile=App.isMobile(),infoUser=ref({}),devicesLength=ref(0),devicesByImei=ref({});
       // devicesByImei.value["860294046421853"]={name:"kisko"};

        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                router.push({
                    name: 'Login',
                    // query: { kisko:2  },
                })
                return
            }
            Fetch.post("/",{token:token}).then((data)=>{ 
                var datas=data.data;
                var userObj=data.data.user;
                userObj.name.entreprise=userObj.en;
                infoUser.value=userObj.name;
                devices.value=datas.devices;
                devices.value.forEach(device => {
                    devicesByImei.value[device.imei]=device;
                });
                devicesLength.value=datas.devices.length;
                $("#MainContent").removeClass("whirl");
               
             });
             $('#main').height($('body').height()-140)
        });


</script>  
<template>
<div class="compte_container">
<Header :infoUser="infoUser" :page="page" />
<section id="MainContent" class="main-section-pt track-minisidebar traditional">
<link rel="stylesheet" href="/css/comming_soon.css">
<div class="normalheader small-header">
    <div class="hpanel">
        <div class="panel-body b-b-n">
            <h2 class="with-button">
                <em class="icon-settings header-icon"></em> Page Maintenance
            </h2>
            <a href="/HelpDoc/AdminConsoleHelpDoc?helpDocType=1" target="_blank" title="Help" class="b btn btn-circle btn-default ml pull-right"><i class="f-17 fa fa-question text-muted1"></i></a>
        </div>
        <div class="panel-body b-b-n p0">
            <div class="vertical-container background" id="main">
                <div class="vertical-body">
                    <div class="container">
                    <h2 class="title">En cours de développement.</h2>
                        <p style="font-size: 20px;">Revenez sous peu ou appelez le service client pour plus de détails.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

</div>
</template>    

<style>
  
</style>
