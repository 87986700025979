// store/index.js
import { createStore } from 'vuex'
import stack from './modules/stack'
import Cookie from '../classes/Cookie'
const store = createStore({
    modules: {
        stack,
    },
    state () {
        return {
          count: 0,
          token:null,
          entreprise:"SUNUTRACK"
        }
    },
    mutations: {
        increment (state) {
          state.count++
        },
        setToken(state){
          state.token=Cookie.getCookie("token");
        }
    }
})

export default store
