import { createRouter, createWebHistory } from 'vue-router'
import Home from '/src/components/Home.vue'
import History from '/src/components/History.vue'
import HistoryMobile from '/src/components/HistoryMobile.vue'
import Login from '/src/components/Login.vue'
import Dashboard from '/src/components/Dashboard.vue'
import Home_mobile from '/src/components/Home_mobile.vue'
import EditDevice from '/src/components/EditDevice.vue'
import EditUser from '/src/components/EditUser.vue'
import Compte from '/src/components/Compte.vue'
import Rapport from '/src/components/Rapport.vue'
import Admin from '/src/components/Admin.vue'
import Geofence from '/src/components/Geofence.vue'
import Principal from '/src/components/Principal.vue'
import Rapport_Kilo_Mobile from "/src/components/rapport_kilo_mobile.vue";
import Rapport_mobile from '/src/components/Rapport_mobile.vue'
import Maintenance from '/src/components/Maintenance.vue'
import Client from '/src/components/Client.vue'
import Brutes from '/src/components/Brutes.vue'
import Devices from '/src/components/Device.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/historique',
        name: 'History',
        component: History,
    },
    {
        path: '/historique_mobile',
        name: 'HistoryMobile',
        component: HistoryMobile,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/mobile',
        name: 'Home_mobile',
        component: Home_mobile,
    },
    {
        path: '/editdevice',
        name: 'EditDevice',
        component: EditDevice,
    },
    {
        path: '/edituser',
        name: 'EditUser',
        component: EditUser,
    },
    {
        path: '/compte',
        name: 'Compte',
        component: Compte,
    },
    {
        path: '/rapport',
        name: 'Rapport',
        component: Rapport,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
    },
    {
        path: '/geofence',
        name: 'Geofence',
        component: Geofence,
    },
    {
        path: '/rapport_kilo_mobile',
        name: 'rapport_kilo_mobile',
        component: Rapport_Kilo_Mobile,
    },
    {
        path: '/admin/users',
        name: 'admin_users',
        component: Principal,
    },
    {
        path: '/rapport_mobile',
        name: 'rapport_mobile',
        component: Rapport_mobile,
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
    },
    {
        path: '/admin/clients',
        name: 'clients',
        component: Client,
    },
    {
        path: '/admin/brutes',
        name: 'brutes',
        component: Brutes,
    },
    {
        path: '/admin/devices',
        name: 'devices',
        component: Devices,
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
