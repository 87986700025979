class Cookie{
    constructor(){
    }
    setCookie(key,value,date) {
      // console.log(!document.cookie.split('; ').find((row) => row.startsWith(key)));
        // if (!document.cookie.split('; ').find((row) => row.startsWith(key))) {
          //  document.cookie = key+"="+value+"; expires="+date+"; SameSite=Lax; Secure";
            document.cookie = key+"="+value+"; expires="+date+";path=/";
        // }
    }
    getCookie(name) {
      var cookie=null;
      document.cookie.split('; ').some((item) => {
        var value=item.split("=")[1];
        var key=item.split("=")[0];
        if (key==name) { cookie=value }
      });
       return cookie;
    }
}
export default new Cookie();