import { createApp,defineCustomElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import signalBar from "./customElements/signalBar.ce.vue";
import './index.css'
import JsonExcel from "vue-json-excel3";
import { createI18n } from 'vue-i18n'
import en from './locales/en.json';
import fr from './locales/fr.json';

const messages = {
  en,
  fr
};

const userLocale = navigator.language || navigator.userLanguage;
const i18n = createI18n({
  locale: userLocale.includes('fr') ? 'fr' : 'en',
  fallbackLocale: 'en',
  messages
});
var kisko =2;
createApp(App).component("downloadExcel", JsonExcel).use(router).use(store).use(i18n).mount('#app')
customElements.define('signal-bar', 
defineCustomElement(signalBar)
);