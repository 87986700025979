class DateTime{
    constructor(){
    }
   addDays(days,date) {
        date.setDate(date.getDate() + days);
        return date;
    }
    addHours(hours,date) {
        date.setHours(date.getHours() + hours);
        return date;
    }
}
export default new DateTime();