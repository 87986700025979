class Fetch{
    base="https://server.sunutrack.com:8443";
    constructor(){
    }
    setBase(base){
        this.base=base;
    }
    getUrl(path){
      if (path.includes("http")) {
        return path;
      }
      return this.base+path;
    }
    async post(url,param={}){
        var data={method:"POST",body: JSON.stringify(param), headers: {"Content-type": "application/json; charset=UTF-8"}};
        
        var rep= await fetch(this.getUrl(url),data);
        let reponse = await rep.json();
        return reponse;
      }
    async  get(url){
      var rep= await fetch(this.getUrl(url));
      let reponse = await rep.json();
      return reponse;
    }

}
export default new Fetch();