<script setup>
    import moment from 'moment'
    import store from '../store'
    import {onMounted,ref,computed } from 'vue'
    import Fetch from '../classes/Fetch'
    import Cookie from '../classes/Cookie'
    import DateTime from '../classes/DateTime';
    import momentLang from '../classes/momentLang'
    import Header from "./Header.vue";
    import App from '../classes/App'
    import { useRoute,useRouter } from 'vue-router';


        var token=Cookie.getCookie("token_admin"),devices=ref([]),whatsappToken=ref(null),page="compte",route = useRoute(),router= useRouter(),isMobile=App.isMobile(),infoUser=ref({}),devicesLength=ref(0),devicesByImei=ref({}),users=ref([]),dataTable=null,selectedCompte=ref(null),device=ref({driver:{prenom:null,nom:null},imei:null,type:null,immat:null,sim:null,consommation:null,modelVehicule:null,typeVehicule:null,note:null,relay:null}),currentUserId=null,userEn=null,adminEn=null,user=ref({name:{prenom:null,nom:null},login:null,password:null,droit:1,telephone:null,email:null,countryName:null,country:"sn",langName:null,lang:"fr",entreprise:null,whatsapp:{alarme:0},mail:{alarme:0}}),currentEntreprise=ref("");
       // devicesByImei.value["860294046421853"]={name:"kisko"};

        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                router.push({
                    name: 'Login',
                    // query: { kisko:2  },
                })
                return
            }
            Fetch.post("/getUsers",{token:token}).then((data)=>{ 
                var userObj=data.user;
                currentEntreprise.value=userObj.en;
                userObj.name.entreprise=userObj.en;
                infoUser.value=userObj.name;
                adminEn=data.user.en;
                var usersCopy=[]
                data.data.forEach(user => {
                    if (!user.whatsapp) {
                        user.whatsapp={alarme:0}; 
                    }
                    if (!user.mail) {
                        user.mail={alarme:0}; 
                    }
                    usersCopy.push(user);
                });
                users.value=usersCopy;
                if (data.droit<3) {
                    router.push({
                        name: 'Login',
                        query: { code:2  },
                    })
                }
                $("#MainContent").removeClass("whirl");
                setTimeout(() => {
                    setDatatable("users_table");
                }, 200);
             });

        });

function setDatatable(id) {
    if($.fn.dataTable.isDataTable("#"+id)){
                dataTable.destroy();
            }
            dataTable = $("#"+id).DataTable({
            "sDom": 'r<"row"<"col-sm-12"<"pull-left"l><"dt-filter-input-custom col-sm-6 col-md-offset-6"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
            "scrollX": true,
            colReorder: true,
            "searching": true,
            "autoWidth": false,
            "bDestroy": true,
            // "ordering": false,
            "order": [[0,'asc']],
            "bPaginate": false,
            "bInfo": false,
            "bStateSave": true,
            // "iDisplayLength": 25,
            "bLengthChange": false,
            "language": {
                "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
                "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
                //"searchPlaceholder": "Search Fleet",
                "url": "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
                "paginate": {
                    "next": " ",
                    "previous": " "
                }
            },
          
            "drawCallback": function (settings) {
                
                
            }
        }); 
}
    $(document).ready(function() {
        $(".select2").select2({
            theme: "bootstrap",
            allowClear: !0,
        });
    });

function showEditModal(imei) {
    $("#CommonImportSummaryModal").modal("show");
}
var actionUser=null,currentIndex=0;
function showModalUser(index) {
    currentIndex=index;
    actionUser="add";
    $("#formCreateUser")[0].reset();
    user.value["_id"]=undefined;
    if(index != "A"){
        var userToEdit={...users.value[index]};
        userToEdit.name={...userToEdit.name}
        user.value=userToEdit;
        actionUser="update";
    }
    $("#createUserModal").modal("show");
}
function EditDevice(imei) {
    var deviceToEdit={...devicesByImei.value[imei]};
    delete deviceToEdit.lastDateAccOff;
    delete deviceToEdit.lastDateAccOn;
    delete deviceToEdit.lastDateMouve;
    delete deviceToEdit.lastDateStatic;
    delete deviceToEdit.position;
    deviceToEdit.user=currentUserId;
    deviceToEdit.driver={...deviceToEdit.driver}
    device.value=deviceToEdit;
   console.log(device.value); 
    $("#imeiEdit").attr("disabled",true);
//    Fetch.post("/saveDevice",{token:token,data:device}).then((data)=>{ 
//         if(data.success){
//             SuccessToastr("La modification a réussi!");
//         }else{
//             ErrorToastr("Une erreur est survenue lors de la modification du véhicule");
//         }
//     });
}
function saveDevice() {
    // device.driver={prenom:prenom.value??undefined,nom:nom.value,nom:nom.value??undefined},device.imei=imei.value,device.type=$("#validationServer05").find(':selected').val(),device.immat=immat.value,device.sim=sim.value,device.consommation=consommation.value,device.modelVehicule=modelVehicule.value,device.typeVehicule=typeVehicule.value,device.note=note.value;
    device.value["relay"]=$('#relayCheckbox').prop('checked')?1:0
    device.value["user"]=currentUserId;
    device.value["en"]=userEn;
    console.log(device.value);
   Fetch.post("/saveDevice",{token:token,data:device.value}).then((data)=>{ 
        console.log(data);
        if(data.success){
            if (data.data.upsertedId) {
                device._id=data.data.upsertedId;
            } 
            devicesByImei.value[device.value.imei]=device.value;
            SuccessToastr("Enregistrement réussi!");
            $(".dataTables_empty").hide();
            $("#imeiEdit").attr("disabled",false);
        }else{
            ErrorToastr("Une erreur est survenue lors de l'enregistrement du véhicule");
        }
        setTimeout(() => {
            $("#formCreateDevice")[0].reset();
        }, 200);
    });
}

function setDevices(index) {
    selectedCompte.value=users.value[index].name.prenom+" "+users.value[index].name.nom;
    devicesByImei.value=users.value[index].devices??{};
    currentUserId=users.value[index]._id;
    userEn=users.value[index].en;
  $("#CommonImportSummaryModal").modal("show");
    setTimeout(() => {
        $("#imeiEdit").attr("disabled",false);
        $("#fieldtypeVehicule").val("voiture");
        $("#formCreateDevice")[0].reset();
    }, 200);
  setTimeout(() => {
    // setDatatable("devices_table");
    $("#validationServer05").val("vt100").trigger('change');
 }, 200);
}
var pays={gmb:"Gambia",sn:"Sénégal"};
var langues={fr:"Français",en:"English"};
function saveUser() {
    user.value.en=adminEn;
    user.value.countryName=pays[user.value.country];
    user.value.langName=langues[user.value.lang];
    var dataToSave={...user.value};delete dataToSave.devices;
    Fetch.post("/saveUser",{data:dataToSave,token:token}).then((data)=>{
        console.log(data);
        if (data.success) {
            SuccessToastr("Enregistrement réussi!");
            user.value['_id']=data._id;
            if (actionUser=="add") {
                users.value.push({...user.value});
            }else{
                users.value[currentIndex]={...user.value};
            }
            $(".dataTables_empty").hide();
        } else{
            ErrorToastr("Une erreur est survenue lors de l'enregistrement de l'utilisateur");
        }
    }).catch((err)=>{console.log(err);})
   }
console.log(router.getRoutes());
   var expireCookie=DateTime.addDays(-2,new Date()).toUTCString();
    function login(login,password) {
        $("#MainContent").addClass("whirl");
       var body={login:login,password:password}
        Fetch.post("/login",body).then((data)=>{
            if (data.error) {
                ErrorToastr(data.message);
            }else{
                var expireCookie=DateTime.addDays(1,new Date()).toUTCString()
                Cookie.setCookie('token',data.token,expireCookie);
                // router.push({
                //         name: 'Home'
                //     });
                window.open("/", '_blank');
               //location.replace("/");
            }
            $("#MainContent").removeClass("whirl");
            // console.log(data);
            }).catch((err)=>{console.log(err);})
    }
    function setWhatsappToken() {
        $("#MainContent").addClass("whirl");
        Fetch.post("https://server.sunutrack.com:2083/setWhatsappToken",{token:token,whatsappToken:whatsappToken.value}).then((data)=>{ 
            if (data.success) {
                SuccessToastr("Le token est modifier avec success");
            }else{
                ErrorToastr("Une erreur est survenue");
            }
            $("#MainContent").removeClass("whirl");
        });
    }

        var json_fields=ref({
        "Client": "name",
        "login": "login",
        "Unité Simple": "simple",
        "Unité Carburant": "fuel",
        "Total": "total",
        "Montant Simple": "simple_price",
        "Montant Carburant": "fuel_price",
        "Montant Total": "total_price",
        });
        var json_data=ref([]),filename=ref("filename.xls");
function startDownload() {
    var json_data_copy=[];
    var total_simple_price=0;
    var total_fuel_price=0;
    var total_unite=0;
    var total_simple=0;
    var total_fuel=0;
        users.value.forEach((user)=>{
            if (user.active!=false) {
                var simple=user.devices.filter(device => {return device.isFuel !=true}).length;
                var carburant=user.devices.filter(device => {return device.isFuel ==true}).length;
                var total=user.devices.length;
                var name=user.name.prenom+" "+user.name.nom;
                var login=user.login;
                var simple=simple;
                var fuel=carburant;
                var total=total;
                var simple_price=simple*2500;
                var fuel_price=carburant*3500;
                total_simple_price+=simple_price;
                total_simple+=simple;
                total_fuel+=carburant;
                total_fuel_price+=fuel_price;
                var total_price=fuel_price+simple_price;
                total_unite+=total;
                var data={name:name,login:login,simple:simple,fuel:fuel,total:total,simple_price:simple_price,fuel_price:fuel_price,total_price:total_price};
                json_data_copy.push(data);
            }
        });
        
        json_data_copy.push({name:"",login:"",simple:total_simple,fuel:total_fuel,total:total_unite,simple_price:total_simple_price,fuel_price:total_fuel_price,total_price:(total_fuel_price+total_simple_price)});
        json_data.value=json_data_copy;
        //json_fields.value=flotte_fields;
        filename.value="Compta_"+moment().format("DD-MM-YYYY")+".xls";
    
}
function printAsPdf(){
    document.title = 'compta_'+moment().format("DD-MM-YYYY");
    printJS({printable:'comptaDiv',scanStyles: false, type: 'html',header: infoUser.value["entreprise"]=="gts"?"GTS AFRIQUE Facture":"SUNUTRACK Facture",css:["/css/rapportActiviteStyle.css","/css/bootstrap.css"]})
}
</script>  
<template>
<div class="compte_container">
<Header :infoUser="infoUser" :page="page"></Header>
 


<section id="MainContent" class="main-section-pt track-minisidebar traditional whirl">


<input id="AddEditStatus" name="AddEditStatus" type="hidden" value="Add">
<input id="TerritorialPostUrl" name="TerritorialPostUrl" type="hidden" value="/Setup/TerritorialFence/Insert">


<div class="normalheader small-header">
    <div class="hpanel">
        <div class="panel-body">
            <h2 class="with-button">
                <em class="icon-user header-icon"></em><span class="master_title">Gestion du compte</span>
            </h2>
            <div class="pull-right">
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-primary btn-square" aria-expanded="false">
                        <em class="zmdi zmdi-more-vert zmdi-hc-lg"></em>
                    </button>
                    <ul role="menu" class="dropdown-menu dropdown-menu-more pull-right">
                        <li>
                            <a href="javascript:;" @click="showModalUser('A')"><em class="zmdi zmdi-account-add"></em>Créer un utilisateur</a>
                        </li>
                        <li>
                            <a href="javascript:;" data-toggle="modal" data-target="#modalComtability"><em class="zmdi zmdi-download"></em>Exporter Excel</a>
                        </li>
                    </ul>
                </div>
                &nbsp;
                <div class="pull-right">
                    <a href="/" class="btn btn-default btn-md btn-square"><em class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg em-icon"></em>Sortir</a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container" v-if="currentEntreprise=='sunutrack'" style="margin: 28px;">
        <div class="row">
            <div class="col-md-8">
                <input placeholder="Saisir le token" v-model="whatsappToken" type="text" name="" class="form-control">
            </div>
            <div class="col-md-2">
                <button @click="setWhatsappToken()" class="btn btn-primary btn-sm" style="margin-right: 5px;">
                    <i class="fa fa-wpexplorer" style="color: rgb(255, 255, 255);"></i> Définir
                </button>
            </div>
        </div>
    </div> -->
    <div class="hpanel animated fadeIn" style="display:none" id="TerritorialFenceDownloadAndImportDiv">
        <div class="panel-body b-t-n">
            <div class="row">
                <div class="col-md-8">
                    <a class="btn btn-primary btn-square" onclick="download_DemoFile()" href="javascript:;" id="btnDemoFile" title="Click to Download Sample File">
                        <i class="zmdi zmdi-format-valign-bottom em-icon"></i>Sample File
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span class="btn btn-default btn-file">
                                Browse…
                                <input type="file" name="TerritorialExcelSheetUpload" id="TerritorialFlUploadcsv" accept=".xlsx" class="form-control">
                            </span>
                        </span>
                        <input type="text" class="form-control" readonly="readonly">

                        <span class="input-group-btn">
                            <button class="btn btn-primary btn-flat" id="btnUploadTerritorial" data-modaltitle="Territorial Import Summary" data-modalwidth="w-60per" title="ImportExcelSheet" onclick="btnImportTerritorial_Click(this)"><i class="zmdi zmdi-format-valign-top"></i></button>
                        </span>
                        <a class="btn btn-default btn-square pull-right" href="javascript:;" id="Close_TerritorialDownloadAndImportDiv" title="Close">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hpanel">
        <div class="panel-body b-n">
            <div class="POIZone-panel-body">
                <div class="container-fluid">
                    <table id="users_table" class="display table">
                        <thead>
                            <tr>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Login</th>
                                <th>Password</th>
                                <th>Téléphone</th>
                                <th>Email</th>
                                <th>Entreprise</th>
                                <th>Unités</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user,index) in users" :key="index">
                                <td>{{user.name.prenom}}</td>
                                <td>{{user.name.nom}}</td>
                                <td>{{user.login}}</td>
                                <td>{{user.password}}</td>
                                <td>{{user.telephone}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.entreprise}}</td>
                                <td>{{user.devices?Object.keys(user.devices).length:0}}</td>
                               <td>
                                    <button @click="showModalUser(index)" class="btn btn-primary btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-pencil" style="color: rgb(255, 255, 255);"></i> Modifier
                                    </button>
                                    <button @click="setDevices(index)" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-car" style="color: rgb(255, 255, 255);"></i> devices
                                    </button>
                                    <button @click="login(user.login,user.password)" class="btn btn-info btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-assistive-listening-systems" style="color: rgb(255, 255, 255);"></i> Connecter
                                    </button>
                                    <button @click="EditDevice(index)" class="btn btn-danger btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-trash" style="color: rgb(255, 255, 255);"></i> Désactiver
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<div id="CommonImportSummaryModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog w-98per">
        <div class="modal-content modal-content-custom">
            <div class="modal-header">
                <button type="button" onclick="CloseCommonImportSummaryModal()" class="close">×</button>
                <h4 class="modal-title">Gestion du compte de {{selectedCompte}} </h4>
            </div>
            <div class="modal-body">
                <div id="devicesDiv">
                    <table id="devices_table" class="display table">
                        <thead>
                            <tr>
                                <th>Imei</th>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Conso</th>
                                <th width="70px;">Bloqué</th>
                                <th>immat</th>
                                <th>Création</th>
                                <th width="60px;">Relay</th>
                                <th width="100px;">Max vitesse</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(device,index) in devicesByImei" :key="index">
                                <td>{{devicesByImei[index].imei}}</td>
                                <td>{{devicesByImei[index].driver.prenom}}</td>
                                <td>{{devicesByImei[index].driver.nom}}</td>
                                <td>{{device.consommation}}/100</td>
                                <td> {{device.position?device.position.Blocked==1?"OUI":"NON":"Inconnu"}}</td>
                                <td>{{devicesByImei[index].immat}}</td>
                                <td>{{moment(devicesByImei[index].created).format("DD/MM/YYYY")}}</td>
                                <td>{{device.relay==1?"OUI":"NON"}}</td>
                                <td>{{devicesByImei[index].overSpeed}}</td>
                                <td>
                                    <button @click="EditDevice(index)" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-pencil" style="color: rgb(255, 255, 255);"></i> Modifier
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <form id="formCreateDevice">
                    <h3 style="text-align:center" >Ajouter un appareil</h3>
                    <div class="form-row">
                        <div class="col-md-2 mb-3">
                            <label for="imeiEdit">Imei</label>
                            <input type="text" class="form-control is-invalid" id="imeiEdit" placeholder="Imei" v-model="device.imei" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer01">Prénom</label>
                            <input type="text" class="form-control is-valid" id="validationServer01" placeholder="Prénom" v-model="device.driver.prenom" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer02">Nom</label>
                            <input type="text" class="form-control is-valid" id="validationServer02" placeholder="Nom" v-model="device.driver.nom" required>
                       
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServerUsername">Immatriculation</label>
                            <input type="text" class="form-control is-invalid" id="validationServerUsername" placeholder="Immatriculation" v-model="device.immat" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer03">Sim</label>
                            <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Sim" v-model="device.sim" required>
                           
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="fieldtypeVehicule">typeVehicule</label>
                            <select name="country" v-model="device.typeVehicule" id="fieldtypeVehicule" class="form-control">
                                <option value="voiture" selected>Voiture</option>
                                <option value="bus">Bus</option>
                                <option value="camion">Camion</option>
                                <option value="moto">Moto</option>
                                <option value="minicar">Mini Car</option>
                                <option value="engin">Engin</option>
                                <option value="betoniere">Bétonière</option>
                                <option value="minitruck">Mini Truck</option>
                                <option value="pelleteuse">Pelleteuse</option>
                                <option value="pickup">Pickup</option>
                                <option value="suv">Suv</option>
                                <option value="van">Van</option>
                            </select>
                           
                        </div>
                    </div>


                    <div class="form-row">
                        <div class="col-md-2 mb-3">
                            <label for="validationServer05">Type</label>
                            <select  class="form-control" v-model="device.type" id="validationServer05" name="type" required>
                                <option selected value="vt100">V100</option>
                                <option selected value="vt100l">V100L</option>
                                <option value="vt200">V200</option>
                                <option value="vt200l">V200L</option>
                                <option value="g4n20">G4N20</option>
                                <option value="seeworld">Weeworld</option>
                                <option value="ob22">OB22</option>
                                <option value="fmb140">FMB140</option>
                                <option value="fmb920">FMB920</option>
                                <option value="sino">SINO</option>
                                <option value="mico">MICO</option>
                                <option value="relay">Rellay</option>
                            </select>
                        </div>
                        <div class="col-md-2 mb-3">
                            <div><label for="validationServer04">Relay</label></div>
                            <label class="switch">
                                <input  type="checkbox" id="relayCheckbox" v-model="device.relay" :checked="device.relay">
                                <span></span>
                            </label>
                        </div> 
                        <div class="col-md-2 mb-3">
                            <label for="validationServer005">modelVehicule</label>
                            <input type="text" class="form-control is-invalid" id="validationServer005" placeholder="model du Véhicule" v-model="device.modelVehicule" required>
                       
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer023">Note</label>
                            <textarea class="form-control" id="validationServer023" placeholder="Ajouter une note" v-model="device.note"></textarea>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer105">Consommation</label>
                            <input type="number" class="form-control is-invalid" id="validationServer105" placeholder="Consommation au 100" v-model="device.consommation" required>
                           
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer105">Arrêt Autorisé(En heure)</label>
                            <input type="number" class="form-control is-invalid" id="validationServer105" placeholder="Temps d'arrêt autorisé" v-model="device.authorisedStop" required>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button id="btnDownloadDevice" @click="saveDevice()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal"><i class="icon-action-redo">&nbsp;</i>Enregistrer</button>
                        <span class="text-danger" id="MessageSpan"></span>
                    </div>
                    <div class="col-md-4">
                        <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                            <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary d-none">Submit</button>
                        <button onclick="CloseCommonImportSummaryModal()" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    <div id="createUserModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog w-98per">
            <div class="modal-content modal-content-custom">
                <div class="modal-header">
                    <button type="button" data-dismiss="modal" aria-label="Close" class="close">×</button>
                    <h4 class="modal-title text-center">Créer un client  </h4>
                </div>
                <div class="modal-body">
                    <form action="" method="post" id="formCreateUser">
                        <div class="form-row">
                            <div class="col-md-2 mb-20">
                                <label for="validationServer001">Prénom</label>
                                <input type="text" class="form-control is-valid" id="validationServer001" placeholder="Prénom" v-model="user.name.prenom" required>
                            </div>
                            <div class="col-md-2 mb-20">
                                <label for="validationServer002">Nom</label>
                                <input type="text" class="form-control is-valid" id="validationServer002" placeholder="Nom" v-model="user.name.nom" required>
                        
                            </div>
                            <div class="col-md-2 mb-20">
                                <label for="validationEntreprise">Entreprise</label>
                                <input type="text" class="form-control is-invalid" id="validationEntreprise" placeholder="Nom de l'entreprise" v-model="user.entreprise" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Alarmes Stop</label>
                                <select name="alarme" id="AlarmesStop" v-model="user.stopAlarme" class="form-control">
                                    <option value="0" selected>NON</option>
                                    <option value="1">OUI</option>
                                </select>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationInterval">Interval de restriction de Stop</label>
                                <input type="text" class="form-control is-invalid" id="validationInterval" placeholder="Interval(début et fin séparé par une virgule)" v-model="user.stopInterval" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-20">
                                <label for="validationServerEmail">Email Personnel</label>
                                <input type="text" class="form-control is-invalid" id="validationServerEmail" placeholder="Email" v-model="user.email" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer003">Téléphone</label>
                                <input type="text" class="form-control is-invalid" id="validationServer003" placeholder="Numero de téléphone" v-model="user.telephone" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Alarmes Email</label>
                                <select name="alarme" id="adressesMail" v-model="user.mail.alarme" class="form-control">
                                    <option value="0" selected>NON</option>
                                    <option value="1">OUI</option>
                                </select>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Alarmes WhatsApp</label>
                                <select name="alarme" id="whatsappPhones" v-model="user.whatsapp.alarme" class="form-control">
                                    <option value="0" selected>NON</option>
                                    <option value="1">OUI</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-20">
                                <label for="validationServer05">WhatsApp</label>
                                <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Numéros WhatsApp" v-model="user.whatsapp.phone" required>
                            </div>
                            <div class="col-md-6 mb-20">
                                <label for="validationServer05">Email Alarmes(Séparé par des virgules)</label>
                                <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Adresses Email" v-model="user.mail.emails" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-20">
                            <label for="validationServer013">Login</label>
                            <input type="text" class="form-control is-invalid" id="validationServer013" placeholder="Login" v-model="user.login" required>
                        
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Mot de passe</label>
                                <input type="password" class="form-control is-invalid" id="validationServer05" placeholder="Mot de passe" v-model="user.password" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer04">Pays</label>
                                <select name="country" v-model="user.country" id="codePays" class="form-control">
                                    <option value="sn" selected>Sénégal</option>
                                    <option value="gmb">Gambia</option>
                                    <option value="gn">Guinée Conakry</option>
                                    <option value="gnb">Guinée Bissau</option>
                                </select>
                        
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Langue</label>
                                <select name="lang" id="codelang" v-model="user.lang" class="form-control">
                                    <option value="fr" selected>Français</option>
                                    <option value="en">Anglais</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row" style="margin: 0px;">
                        <div class="col-6">
                            <button id="btnDownload" @click="saveUser()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal"><i class="icon-action-redo">&nbsp;</i>Enregistrer</button>
                            <span class="text-danger" id="MessageSpan"></span>
                        </div>
                        <div class="col-4">
                            <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                                <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                            </div>
                        </div>
                        <div class="col-2">
                            <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary d-none">Submit</button>
                            <button data-dismiss="modal" aria-label="Close" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal comptabilité détaillé start -->
<div id="modalComtability" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog w-98per">
        <div class="modal-content modal-content-custom">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" class="close">×</button>
                <h4 class="modal-title">Gestion des clients</h4>
            </div>
            <div class="modal-body">
                <div id="comptaDiv">
                    <table id="compta_table" class="display table">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Login</th>
                                <th>U.Simple</th>
                                <th>U.Fuel</th>
                                <th>Total</th>
                                <th>M.Simple</th>
                                <th>M.Fuel</th>
                                <th>M.Total</th>
                            </tr>
                        </thead>
                        <tbody :set="total={fuel:0,simple:0,unites:0,montant_simple:0,montant_fuel:0}">
                            <tr v-for="(user,index) in users" :key="index" :set="b={total_simple:user.devices.filter(device => {return device.isFuel !=true&&device.active !=false}).length,total_fuel:user.devices.filter(device => {return device.isFuel ==true&&device.active !=false}).length,unites:user.devices.length}">
                               <template  v-if="(user.active!=false)">
                                <template>
                                    {{total.simple+=b.total_simple  }}
                                    {{total.fuel+=b.total_fuel  }}
                                    {{total.montant_simple+=b.total_simple*2500  }}
                                    {{total.montant_fuel+=b.total_fuel*3500  }}
                                    {{total.unites+=b.total_simple+b.total_fuel  }}
                               </template>
                                <td>{{user.name.prenom}} {{user.name.nom}}</td>
                                <td>{{user.login}}</td>
                                <td>{{b.total_simple}}</td>
                                <td>{{b.total_fuel}}</td>
                                <td>{{ b.unites }}</td>
                                <td>{{b.total_simple*2500}}</td>
                                <td>{{b.total_fuel*3500}}</td>
                                <td>{{((b.total_fuel*3500)+(b.total_simple*2500))}}</td>
                               </template>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><b>{{total.simple}}</b></td>
                                <td><b>{{total.fuel}}</b></td>
                                <td><b>{{ total.fuel+total.simple }}</b></td>
                                <td><b>{{total.montant_simple}}</b></td>
                                <td><b>{{total.montant_fuel}}</b></td>
                                <td><b>{{total.montant_fuel+total.montant_simple}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <download-excel
  class="btn btn-default"
  :before-generate="startDownload"
  :data="json_data"
  :fields="json_fields"
  worksheet="My Worksheet"
  :name="filename"
>
<img src="/images/icon-excel.png" data-toggle="tooltip" data-placement="bottom" title="" alt="img" data-original-title="Export to xls">
</download-excel>
<a href="javascript:;" @click="printAsPdf()" id="btnPrintReport"><img src="/images/icon-print.png" data-toggle="tooltip" data-placement="bottom" title="" alt="img" data-original-title="Print"></a>
                    </div>
                    <div class="col-md-4">
                        <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                            <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary d-none">Submit</button>
                        <button  type="button" data-dismiss="modal" class="btn btn-default btn-lg btn-square">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
<!-- modal compta end  -->
</template>    

<style>
    #NearByVehicleList {
        min-height: calc(100vh - 418px);
        height: calc(100vh - 418px);
        overflow-y: hidden;
        margin-right: 10px;
    }
    .mb-20{
       margin-bottom: 20px;
    }
    .daterangepicker.dropup {
        top: calc(100vh - 435px) !important;
    }

    .scrollable2 {
        height: calc(100vh - 385px) !important;
        overflow: auto;
    }

    .footer_mapviewsidebar {
        bottom: 0px !important;
    }

    .nav-tab-inner-widget li {
        width: calc(100% / 5);
    }

    .nav-tab-5 li {
        width: calc(100% / 5);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }

        #_OffSideBarMapViewListDiv {
        min-height: calc(100vh - 248px);
        height: calc(100vh - 248px);
        overflow-y: hidden;
    }
    .nav-tab-4 li {
        width: calc(100% / 4);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }
        .no-table-header thead {
        display: none;
    }

    .table-td-padding td {
        padding: 10px 2px !important;
        border: 0px !important;
    }

    .mw-150 {
        max-width: 150px;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    tr.active td {
        background-color: rgba(33, 150, 243, 0.18) !important;
    }
    .poiZoneMapViewSize {
        position: relative;
        width: 100%;
        height: calc(100vh - 148px);
    }

    .control-gray-ddl option {
        color: #333;
    }

    .display-block {
        display: block;
    }

    .display-none {
        display: none;
    }

    .collapsedBtnPOI {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .btn_switch_grp .btn_switch {
        border-radius: 50px;
        border: 1px solid #2196F3;
    }

        .btn_switch_grp .btn_switch.active,
        .btn_switch_grp .btn_switch:hover {
            background-color: #2196F3;
            color: #fff;
        }

    .map-panel-control {
        right: 10px;
        bottom: 63px;
    }

    .mapOptionsDashboard .btn-default {
        width: 41px;
        height: 40px;
    }

    .isFullScreen #rightDivHeight {
        width: 100% !important;
        float: left;
    }

    .isFullScreen #leftDivList {
        width: 0px !important;
        float: left;
        display: none !important;
    }

    .POIZone-panel-body #rightDivHeight {
        width: calc(100% - 370px);
        float: left;
    }


    .POIZone-panel-body #leftDivList {
        width: 370px;
        float: left;
    }
    #compta_table b{
        font-size: 16px;
        color: #3f51b5;
    }
</style>
