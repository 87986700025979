<template>
    <div class="panel panel-default panel-flat m-report-body bgLightgray">
        <div class="panel-body">
            <div id="ReportDetailDiv">
                <div v-for="(point, index) in historyData" :key="index" :class="'card-panel report-card-panel htd_'+point.status" @click="handleclick(point.date,point.fin,point.distance)">
                    <div class="card-panel-body pb-sm pt">
                        <h4 class="f-15 fw-500 mb0 mt0 text-primary">{{point.status==1?"Mouvement":point.status==2?"Statique":"Inactive"}} <span class="f-12 pull-right text-muted">{{moment(point.date).format("DD/MM/YYYY")}}</span></h4>
                        <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                    </div>
                    <div class="card-panel-body bt pt-sm pb-sm">
                        <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                            <div class="pull-left w-5per">
                                <span class="mloction-circle  light bg-danger-dark"></span>
                            </div>
                            <div class="w-95per  pull-left">
                                <div class="w-80per pull-left">
                                    <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                        <a href="javascript:; ">{{ point.adresse}}</a>
                                    </p>
                                    <p class="f-12 fw-500 mb0 text-muted">{{moment(point.date).format("HH:mm:ss")}}</p>
                                </div>
                                <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">{{ point.duree}}</p></div>
                            </div>
                        </div>
                        <div class="overflow-hidden">
                            <div class="pull-left w-5per">
                                <span class="mloction-circle light bg-green-dark"></span>
                            </div>
                            <div class="w-95per pull-left">
                                <div class="w-80per pull-left">
                                    <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                        <a href="javascript:; ">{{ point.adresse_fin?point.adresse_fin:"---"}}</a>
                                    </p>
                                    <p class="f-12 fw-500 mb0 text-muted">{{moment(point.fin).format("HH:mm:ss")}}</p>
                                </div>
                                <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">{{point.distance}} Kms</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>        
<!-- <table id="rapportActivityTable" class="display table d-none" style="width:100%">
    <thead>
        <tr>
            <th>Etat</th>
            <th>Date</th>
            <th>Debut</th>
            <th>Fin</th>
            <th>Durée</th>
            <th>Adresse Départ</th>
            <th>Adresse arrivée</th>
            <th>Km</th>
            <th>Alarmes</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <template v-for="(point, index) in historyData" :key="index">
            <tr :class="'htd_'+point.status">
                <td>{{point.status==1?"Mouvement":point.status==2?"Statique":"Inactive"}}</td>
                <td> {{moment(point.date).format("DD/MM/YYYY")}}</td>
                <td> {{moment(point.date).format("HH:mm:ss")}}</td>
                <td> {{moment(point.fin).format("HH:mm:ss")}}</td>
                <td> {{ point.duree}}</td>
                <td :title="point.adresse" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:120px;cursor:pointer;"> {{ point.adresse}}</td>
                <td :title="point.adresse_fin?point.adresse_fin:''" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:120px;cursor:pointer;"> {{ point.adresse_fin?point.adresse_fin:"---"}}</td>
                <td> {{point.distance}} Kms</td>
                <td> 0</td>
                <td>  <button class="btn btn-success btn-sm"> Détail</button> </td>
            </tr>
        </template>
    </tbody>
</table> -->
</template>

<script setup>
import moment from 'moment'
const emit=defineEmits(["showTrajet"]);
var props=defineProps({historyData:{}});
function handleclick(debut,fin,distance){
    emit("showTrajet",[debut,fin,distance]);
}
</script>