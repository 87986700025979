<script setup>
    import moment from 'moment'
    import store from '../store'
    import {onMounted,ref,computed } from 'vue'
    import Fetch from '../classes/Fetch'
    import Cookie from '../classes/Cookie'
    import DateTime from '../classes/DateTime';
    import momentLang from '../classes/momentLang'
    import Header from "./Header.vue";
    import App from '../classes/App'
    import { useRoute,useRouter } from 'vue-router';


        var token=Cookie.getCookie("token_admin"),devices=ref([]),whatsappToken=ref(null),page="compte",route = useRoute(),router= useRouter(),isMobile=App.isMobile(),infoUser=ref({}),devicesLength=ref(0),devicesByImei=ref({}),users=ref({}),dataTable=null,selectedCompte=ref(null),device=ref({driver:{prenom:null,nom:null},imei:null,type:null,immat:null,sim:null,consommation:null,modelVehicule:null,typeVehicule:null,note:null,relay:null}),currentUserId=null,userEn=null,adminEn=null,user=ref({name:null,phone:null,email:null,note:null,description:null,date:null,site:null,relance:null,langName:null,coords:null,entreprise:null,intressed:1})
       // devicesByImei.value["860294046421853"]={name:"kisko"};

        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                router.push({
                    name: 'Login',
                    // query: { kisko:2  },
                })
                return
            }
            Fetch.post("/getAdminDevices",{token:token}).then((data)=>{ 
                if (data.droit<3) {
                    router.push({
                        name: 'Login',
                        query: { code:2  },
                    })
                }
                infoUser.value=data.user;
                adminEn=data.user.en;
                var usersCopy={};
                data.users.forEach(user => {
                    usersCopy[user._id]=user;
                });
                users.value=usersCopy;
                console.log(usersCopy);
                devices.value=data.data;
                setTimeout(() => {
                    $("#MainContent").removeClass("whirl");
                    //setDatatable("users_table");
                }, 2000);
             });
             mainContent = $("#MainContent");
             $("#MainContent").addClass("whirl");
        });
        function toFixed(num, fixed) {
          fixed = fixed || 0;
          fixed = Math.pow(10, fixed);
          return Math.floor(num * fixed) / fixed;
        }
function setDatatable(id) {
    if($.fn.dataTable.isDataTable("#"+id)){
                dataTable.destroy();
            }
            dataTable = $("#"+id).DataTable({
            "sDom": 'r<"row"<"col-sm-12"<"pull-left"l><"dt-filter-input-custom col-sm-6 col-md-offset-6"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
            "scrollX": true,
            colReorder: true,
            "searching": true,
            "autoWidth": false,
            "bDestroy": true,
            // "ordering": false,
            "order": [[0,'asc']],
            "bPaginate": false,
            "bInfo": false,
            "bStateSave": true,
            // "iDisplayLength": 25,
            "bLengthChange": false,
            "language": {
                "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
                "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
                //"searchPlaceholder": "Search Fleet",
                "url": "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
                "paginate": {
                    "next": " ",
                    "previous": " "
                }
            },
          
            "drawCallback": function (settings) {
                
                
            }
        }); 
}
    $(document).ready(function() {
        $(".select2").select2({
            theme: "bootstrap",
            allowClear: !0,
        });
    });

function showEditModal(imei) {
    $("#CommonImportSummaryModal").modal("show");
}
var actionUser=null,currentIndex=0;
function showModalUser(index) {
    currentIndex=index;
    actionUser="add";
    $("#formCreateUser")[0].reset();
    user.value["_id"]=undefined;
    if(index != "A"){
        var userToEdit={...users.value[index]};
        user.value=userToEdit;
        actionUser="update";
    }
    $("#createUserModal").modal("show");
}
function EditDevice(imei) {
    var deviceToEdit={...devicesByImei.value[imei]};
    delete deviceToEdit.lastDateAccOff;
    delete deviceToEdit.lastDateAccOn;
    delete deviceToEdit.lastDateMouve;
    delete deviceToEdit.lastDateStatic;
    delete deviceToEdit.position;
    deviceToEdit.user=currentUserId;
    deviceToEdit.driver={...deviceToEdit.driver}
    device.value=deviceToEdit;
   console.log(device.value); 
    $("#imeiEdit").attr("disabled",true);
//    Fetch.post("/saveDevice",{token:token,data:device}).then((data)=>{ 
//         if(data.success){
//             SuccessToastr("La modification a réussi!");
//         }else{
//             ErrorToastr("Une erreur est survenue lors de la modification du véhicule");
//         }
//     });
}
function saveDevice() {
    // device.driver={prenom:prenom.value??undefined,nom:nom.value,nom:nom.value??undefined},device.imei=imei.value,device.type=$("#validationServer05").find(':selected').val(),device.immat=immat.value,device.sim=sim.value,device.consommation=consommation.value,device.modelVehicule=modelVehicule.value,device.typeVehicule=typeVehicule.value,device.note=note.value;
    device.value["relay"]=$('#relayCheckbox').prop('checked')?1:0
    device.value["user"]=currentUserId;
    device.value["en"]=userEn;
    console.log(device.value);
   Fetch.post("/saveDevice",{token:token,data:device.value}).then((data)=>{ 
        console.log(data);
        if(data.success){
            if (data.data.upsertedId) {
                device._id=data.data.upsertedId;
            } 
            devicesByImei.value[device.value.imei]=device.value;
            SuccessToastr("Enregistrement réussi!");
            $(".dataTables_empty").hide();
            $("#imeiEdit").attr("disabled",false);
        }else{
            ErrorToastr("Une erreur est survenue lors de l'enregistrement du véhicule");
        }
        setTimeout(() => {
            $("#formCreateDevice")[0].reset();
        }, 200);
    });
}

function setDevices(index) {
    selectedCompte.value=users.value[index].name.prenom+" "+users.value[index].name.nom;
    devicesByImei.value=users.value[index].devices??{};
    currentUserId=users.value[index]._id;
    userEn=users.value[index].en;
  $("#CommonImportSummaryModal").modal("show");
    setTimeout(() => {
        $("#imeiEdit").attr("disabled",false);
        $("#fieldtypeVehicule").val("voiture");
        $("#formCreateDevice")[0].reset();
    }, 200);
  setTimeout(() => {
    // setDatatable("devices_table");
    $("#validationServer05").val("vt100").trigger('change');
 }, 200);
}
var pays={gmb:"Gambia",sn:"Sénégal"};
var langues={fr:"Français",en:"English"};
function saveUser() {
    $("#MainContent").addClass("whirl");
    user.value.en=adminEn;
    user.value.countryName=pays[user.value.country];
    user.value.langName=langues[user.value.lang];
    var dataToSave={...user.value};delete dataToSave.devices;
    Fetch.post("/saveClient",{data:dataToSave,token:token}).then((data)=>{
        console.log(data);
        $("#MainContent").removeClass("whirl");
        if (data.success) {
            SuccessToastr("Enregistrement réussi!");
            user.value['_id']=data._id;
            if (actionUser=="add") {
                users.value.push({...user.value});
            }else{
                users.value[currentIndex]={...user.value};
            }
            $(".dataTables_empty").hide();
        } else{
            ErrorToastr("Une erreur est survenue lors de l'enregistrement de l'utilisateur");
        }
    }).catch((err)=>{console.log(err);})
   }

    function setWhatsappToken() {
        $("#MainContent").addClass("whirl");
        Fetch.post("https://server.sunutrack.com:2083/setWhatsappToken",{token:token,whatsappToken:whatsappToken.value}).then((data)=>{ 
            if (data.success) {
                SuccessToastr("Le token est modifier avec success");
            }else{
                ErrorToastr("Une erreur est survenue");
            }
            $("#MainContent").removeClass("whirl");
        });
    }
    $(document).ready(function() {
        $("#").on('btnDownload', function() {
            // saveUser();
        });
    });
    function printAsPdf(){
        document.title = 'Facture Ariéré';
        printJS({printable:'printable',scanStyles: false, type: 'html',header: infoUser.value["entreprise"]=="gts"?"GTS AFRIQUE Facture":"SUNUTRACK Facture",css:["/css/rapportActiviteStyle.css","/css/bootstrap.css"]})
    }

    var imeis=["869926040821296","869926040810737","869926040818516","869926040821601"];
</script>  
<template>
<div class="compte_container">
<Header :infoUser="infoUser" :page="page"></Header>
 


<section id="MainContent" class="main-section-pt track-minisidebar traditional whirl">


<input id="AddEditStatus" name="AddEditStatus" type="hidden" value="Add">
<input id="TerritorialPostUrl" name="TerritorialPostUrl" type="hidden" value="/Setup/TerritorialFence/Insert">


<div class="normalheader small-header">
    <div class="hpanel">
        <div class="panel-body">
            <h2 class="with-button">
                <em class="icon-user header-icon"></em><span class="master_title">Gestion des Clients</span>
            </h2>
            <div class="pull-right">
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-primary btn-square" aria-expanded="false">
                        <em class="zmdi zmdi-more-vert zmdi-hc-lg"></em>
                    </button>
                    <ul role="menu" class="dropdown-menu dropdown-menu-more pull-right">
                        <li>
                            <a href="javascript:;" @click="showModalUser('A')"><em class="zmdi zmdi-account-add"></em>Créer un utilisateur</a>
                        </li>
                        <li>
                            <a href="javascript:;" @click="printAsPdf()"><em class="zmdi zmdi-account-add"></em>Imprimer</a>
                        </li>
                        
                    </ul>
                </div>
                &nbsp;
                <div class="pull-right">
                    <a href="/" class="btn btn-default btn-md btn-square"><em class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg em-icon"></em>Sortir</a>
                </div>
            </div>
        </div>
    </div>

    <div class="hpanel animated fadeIn" style="display:none" id="TerritorialFenceDownloadAndImportDiv">
        <div class="panel-body b-t-n">
            <div class="row">
                <div class="col-md-8">
                    <a class="btn btn-primary btn-square" onclick="download_DemoFile()" href="javascript:;" id="btnDemoFile" title="Click to Download Sample File">
                        <i class="zmdi zmdi-format-valign-bottom em-icon"></i>Sample File
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span class="btn btn-default btn-file">
                                Browse…
                                <input type="file" name="TerritorialExcelSheetUpload" id="TerritorialFlUploadcsv" accept=".xlsx" class="form-control">
                            </span>
                        </span>
                        <input type="text" class="form-control" readonly="readonly">

                        <span class="input-group-btn">
                            <button class="btn btn-primary btn-flat" id="btnUploadTerritorial" data-modaltitle="Territorial Import Summary" data-modalwidth="w-60per" title="ImportExcelSheet" onclick="btnImportTerritorial_Click(this)"><i class="zmdi zmdi-format-valign-top"></i></button>
                        </span>
                        <a class="btn btn-default btn-square pull-right" href="javascript:;" id="Close_TerritorialDownloadAndImportDiv" title="Close">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hpanel">
        <div class="panel-body b-n">
            <div class="POIZone-panel-body">
                <div class="container-fluid" id="printable">
                    <table id="users_table" class="display table">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Driver</th>
                                <th>Imei</th>
                                <th>Created</th>
                                <th>Emmission</th>
                                <th>Last data</th>
                                <th>Mois</th>
                                <th>Fuel</th>
                                <th>Montant</th>
                            </tr>
                        </thead>
                        <tbody :set="vars={days:0,total:0,months:0,montant:0}">
                            <tr v-for="(device,index) in devices" :key="index">
                                    <template>
                                        {{ device.date=imeis.includes(device.imei)?device.created:device.date }}
                                        {{vars.days=moment("2023-09-30").diff(moment(device.date),'days')  }}
                                        {{vars.days=vars.days>7?vars.days:0 }}
                                        {{vars.months= toFixed(vars.days/30,3)}}
                                        {{vars.montant= parseInt(device.isFuel?vars.months*3000:vars.months*2000)}}
                                        {{vars.total+=vars.montant}}
                                   </template>
                                    <td>{{users[device.user]?users[device.user].name.prenom:""}} {{users[device.user]?users[device.user].name.nom:""}}</td>
                                    <td>{{device.driver.prenom}} {{device.driver.nom}}</td>
                                    <td>{{device.imei}}</td>
                                    <td>{{moment(device.created).format("DD-MM-YYYY")}}</td>
                                    <td>{{moment(device.date).format("DD-MM-YYYY")}}</td>
                                    <td>{{moment(device.position.date).format("DD-MM-YYYY")}}</td>
                                    <td>{{vars.months}}</td>
                                    <td>{{device.isFuel?"OUI":"NON"}}</td>
                                    <td>{{vars.montant}}</td>
                            </tr>
                        </tbody>
                    </table>
                   <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4 col-sm-4 pr0">
                            <div class="card-panel card-panel-dash box-shadow-1">
                                <div class="card-panel-heading">
                                    <h4>Montant Total<small class="f-11 text-capitalize text-muted fw-600 pull-right"></small></h4>
                                </div>
                                <div class="pt-sm card-panel-body">
                                    <div class="row">
                                        <div class="col-xs-2">
                                            <div class="dash_widget1 bg-green">
                                                <!-- <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABmJLR0QA/wD/AP+gvaeTAAADdElEQVRYhc3YW6jUVRTH8d/2lKYZpSZiIZRdMCG0TIxEu1BWhGgXIfSlJAJ7MQiyB40QJYrIIh/qqSgKSixDKDDDjHywvEX320ulJdiNtEzTTw//bR2G0XM856gtGGb2Wnvt9Z31X3vvNZP8j6R0NQElyS1JpibZnWRFKWXbsQZrBzIAb2rkG/yE/bjvRMAswR+4po47sBQHcPnxhtmBJ1p0pWbp6b6O168L+5Ak2zsrSimS7Egy9HjDbEpyKzoOKXBekglJ3u9rmH8Fg3Fyi25iLdh1uBP3Yjs+x8BjCTMBWzGuRT8ZH9YdtR+vYEQPY8zAzd2ZOBBPYi8ebJOlMbgCQ3oIcgp+xLKjcZqCr7AZF1fIFThYs3MAm/AwrsaAFv8OXFof6Sp8hMcwD3/XmmsrbU9gDEqyNMnONMU6LcmCJJuTjKnj65KMSLInyfokW5KMTzIlyelJ9ibZmOTLJHOTHEzyeillVrcz0wJ1Qc3I7Da2gvG4H2uxE29hUc3ugDpveH08MKlHIHWh2+oiZ7exjcRlOK1FPxg34lF8UB8NvNtjkLrwpLrQ5Da2Z/0n3+IdbKw7Dn7AS3imjmd0Fa87h97PSW5oY1tb3xcmeSHNqbw1yfwkY0spI0sps9Oc1F8kWd0VTJeCF7GljX4w9mDBEXxH1cd0d69B6oJzahGf1cb2cjvQFuCFrdu/NzDD6re7q41tZq2HKRjd5nWOlsOzL4Dew6tt9AM0DdeRZA8WdSfOSd3keSPJA+hfStl3SFlK+QtvJxmd5JHD+F6ZZDE+LaWs7Ga8w4vmcINrW/SjsA8zu/Bfh029BqmLFXyHx1v0y/AZjnhEYHr9MlP7Cmh5Bepfx0PxO+5omXcubtecwJdUXT9ND7Sqr2Au1DTnr2mugoXYhZvq59Wa+6lz4W7o5D9Pc+Of31dA0/FLDbavU+BfsQaLK9wwXF9tV1XfQRX+qT6BqYsO1fQ3uzSt6NjD1YymJ1rTabwEu/WwQWsXoANf46FuzJ1VszOxjkfgT0e4Qo4WZk6th+HdmNsPn2BlJ91z+P7QRugNSME2LD8Kn7m1cMfW8biarTm9henQ3DmnHoVPf02/83wn3Xqs6BVMTwXzNU3XRTW767S5644XzEB8jN80vzwOYFrrvC7/n+lDoDOS3JPkzCQrSykbunA5sfIPijweShTrHmYAAAAASUVORK5CYII="> -->
                                            </div>
                                        </div>
                                        <div class="col-xs-10 text-right">
                                            <h4 class="mt0 mb-0 font_OpenSans fw-600 f-28 text-green"><span id="DrivingTimePerDay">{{vars.total}}</span><small class="f-12 fw-500 text-danger ml-sm"></small></h4>
                                            <!-- <p class="f-12 fw-500 mb-0 text-muted"><span id="DrivingTimePerVehicle">1:04</span>/per vehicle</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<div id="CommonImportSummaryModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog w-98per">
        <div class="modal-content modal-content-custom">
            <div class="modal-header">
                <button type="button" onclick="CloseCommonImportSummaryModal()" class="close">×</button>
                <h4 class="modal-title">Gestion du compte de {{selectedCompte}} </h4>
            </div>
            <div class="modal-body">
                <div id="devicesDiv">
                    <table id="devices_table" class="display table">
                        <thead>
                            <tr>
                                <th>Imei</th>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Conso</th>
                                <th width="70px;">Bloqué</th>
                                <th>immat</th>
                                <th>type véhicule</th>
                                <th width="60px;">Relay</th>
                                <th width="100px;">Max vitesse</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(device,index) in devicesByImei" :key="index">
                                <td>{{index}}</td>
                                <td>{{devicesByImei[index].driver.prenom}}</td>
                                <td>{{devicesByImei[index].driver.nom}}</td>
                                <td>{{device.consommation}}/100</td>
                                <td> {{device.position?device.position.Blocked==1?"OUI":"NON":"Inconnu"}}</td>
                                <td>{{devicesByImei[index].immat}}</td>
                                <td>{{devicesByImei[index].modelVehicule}}</td>
                                <td>{{device.relay==1?"OUI":"NON"}}</td>
                                <td>{{devicesByImei[index].overSpeed}}</td>
                                <td>
                                    <button @click="EditDevice(index)" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-pencil" style="color: rgb(255, 255, 255);"></i> Modifier
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <form id="formCreateDevice">
                    <h3 style="text-align:center" >Ajouter un appareil</h3>
                    <div class="form-row">
                        <div class="col-md-2 mb-3">
                            <label for="imeiEdit">Imei</label>
                            <input type="text" class="form-control is-invalid" id="imeiEdit" placeholder="Imei" v-model="device.imei" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer01">Prénom</label>
                            <input type="text" class="form-control is-valid" id="validationServer01" placeholder="Prénom" v-model="device.driver.prenom" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer02">Nom</label>
                            <input type="text" class="form-control is-valid" id="validationServer02" placeholder="Nom" v-model="device.driver.nom" required>
                       
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServerUsername">Immatriculation</label>
                            <input type="text" class="form-control is-invalid" id="validationServerUsername" placeholder="Immatriculation" v-model="device.immat" required>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer03">Sim</label>
                            <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Sim" v-model="device.sim" required>
                           
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="fieldtypeVehicule">typeVehicule</label>
                            <select name="country" v-model="device.typeVehicule" id="fieldtypeVehicule" class="form-control">
                                <option value="voiture" selected>Voiture</option>
                                <option value="bus">Bus</option>
                                <option value="camion">Camion</option>
                                <option value="moto">Moto</option>
                                <option value="minicar">Mini Car</option>
                                <option value="engin">Engin</option>
                                <option value="betoniere">Bétonière</option>
                                <option value="minitruck">Mini Truck</option>
                                <option value="pelleteuse">Pelleteuse</option>
                                <option value="pickup">Pickup</option>
                                <option value="suv">Suv</option>
                                <option value="van">Van</option>
                            </select>
                           
                        </div>
                    </div>


                    <div class="form-row">
                        <div class="col-md-2 mb-3">
                            <label for="validationServer05">Type</label>
                            <select  class="form-control" v-model="device.type" id="validationServer05" name="type" required>
                                <option selected value="vt100">V100</option>
                                <option value="vt200">V200</option>
                                <option value="g4n20">G4N20</option>
                                <option value="ob22">OB22</option>
                                <option value="fmb140">FMB140</option>
                                <option value="fmb920">FMB920</option>
                                <option value="sino">SINO</option>
                                <option value="mico">MICO</option>
                                <option value="relay">Rellay</option>
                            </select>
                        </div>
                        <div class="col-md-2 mb-3">
                            <div><label for="validationServer04">Relay</label></div>
                            <label class="switch">
                                <input  type="checkbox" id="relayCheckbox" v-model="device.relay" :checked="device.relay">
                                <span></span>
                            </label>
                        </div> 
                        <div class="col-md-2 mb-3">
                            <label for="validationServer005">modelVehicule</label>
                            <input type="text" class="form-control is-invalid" id="validationServer005" placeholder="model du Véhicule" v-model="device.modelVehicule" required>
                       
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer023">Note</label>
                            <textarea class="form-control" id="validationServer023" placeholder="Ajouter une note" v-model="device.note"></textarea>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="validationServer105">Consommation</label>
                            <input type="number" class="form-control is-invalid" id="validationServer105" placeholder="Consommation au 100" v-model="device.consommation" required>
                           
                        </div>
                        <div class="col-md-2 mb-3">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button id="btnDownloadDevice" @click="saveDevice()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal"><i class="icon-action-redo">&nbsp;</i>Enregistrer</button>
                        <span class="text-danger" id="MessageSpan"></span>
                    </div>
                    <div class="col-md-4">
                        <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                            <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary d-none">Submit</button>
                        <button onclick="CloseCommonImportSummaryModal()" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    <div id="createUserModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog w-98per">
            <div class="modal-content modal-content-custom">
                <div class="modal-header">
                    <button type="button" data-dismiss="modal" aria-label="Close" class="close">×</button>
                    <h4 class="modal-title">Gestion du compte de {{selectedCompte}} </h4>
                </div>
                <div class="modal-body">
                    <form action="" method="post" id="formCreateUser">
                        <div class="form-row">
                            <div class="col-md-4 mb-20">
                                <label for="validationServer001">Nom du contact</label>
                                <input type="text" class="form-control is-valid" id="validationServer001" placeholder="Nom complet du contact" v-model="user.name" required>
                            </div>
                            <div class="col-md-4 mb-20">
                                <label for="validationServer002">Téléphones(séparé par des virgules)</label>
                                <input type="text" class="form-control is-valid" id="validationServer002" placeholder="Téléphones" v-model="user.phone" required>
                        
                            </div>
                            <div class="col-md-4 mb-20">
                                <label for="validationEntreprise">Emails(séparé par des virgules)</label>
                                <input type="text" class="form-control is-invalid" id="validationEntreprise" placeholder="Emails" v-model="user.email" required>
                            </div>
                           
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-20">
                                <label for="validationServerEmail">Entreprise</label>
                                <input type="text" class="form-control is-invalid" id="validationServerEmail" placeholder="Nom de l'entreprise" v-model="user.entreprise" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer003">Site Web</label>
                                <input type="text" class="form-control is-invalid" id="validationServer003" placeholder="Site web de l'entreprise" v-model="user.site" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="validationServer05">Coordonnées Gps</label>
                                <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Coordonnées Gps du bureau" v-model="user.coords" required>
                            </div>
                            <div class="col-md-3 mb-20">
                                <label for="inputDate">Dernier Appel/Mail</label>
                                <input type="date" class="form-control is-invalid" id="inputDate" placeholder="Date du dernière Appel" v-model="user.date" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-20">
                            <label for="validationServer013">Date Relance</label>
                            <input type="date" class="form-control is-invalid" id="validationServer013" placeholder="Date de relance" v-model="user.relance" required>
                        
                            </div>
                            <div class="col-md-1 mb-20">
                                <label for="validationServer05">Intéressé</label>
                                <select style="padding: 2px;" class="form-control" v-model="user.intressed" id="validationServer05" name="intressed" required>
                                    <option selected value="1">OUI</option>
                                    <option value="2">NON</option>
                                </select>
                            </div>
                            <!-- <div class="col-md-3 mb-20">
                                <label for="validationServer05">Mot de passe</label>
                                <input type="password" class="form-control is-invalid" id="validationServer05" placeholder="Mot de passe" v-model="user.password" required>
                            </div> -->
                            <div class="col-md-4 mb-20">
                                <label for="validationServer04">Note</label>
                                <textarea v-model="user.note" id="codePays" class="form-control"></textarea>
                        
                            </div>
                            <div class="col-md-4 mb-20">
                                <label for="validationServer05">Description</label>
                                <textarea v-model="user.description" id="validationServer05" class="form-control"></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row" style="margin: 0px;">
                        <div class="col-6">
                            <button id="btnDownload" @click="saveUser()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal"><i class="icon-action-redo">&nbsp;</i>Enregistrer</button>
                            <span class="text-danger" id="MessageSpan"></span>
                        </div>
                        <div class="col-4">
                            <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                                <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                            </div>
                        </div>
                        <div class="col-2">
                            <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary d-none">Submit</button>
                            <button data-dismiss="modal" aria-label="Close" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>    

<style>
    #NearByVehicleList {
        min-height: calc(100vh - 418px);
        height: calc(100vh - 418px);
        overflow-y: hidden;
        margin-right: 10px;
    }
    .mb-20{
       margin-bottom: 20px;
    }
    .daterangepicker.dropup {
        top: calc(100vh - 435px) !important;
    }

    .scrollable2 {
        height: calc(100vh - 385px) !important;
        overflow: auto;
    }

    .footer_mapviewsidebar {
        bottom: 0px !important;
    }

    .nav-tab-inner-widget li {
        width: calc(100% / 5);
    }

    .nav-tab-5 li {
        width: calc(100% / 5);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }

        #_OffSideBarMapViewListDiv {
        min-height: calc(100vh - 248px);
        height: calc(100vh - 248px);
        overflow-y: hidden;
    }
    .nav-tab-4 li {
        width: calc(100% / 4);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }
        .no-table-header thead {
        display: none;
    }

    .table-td-padding td {
        padding: 10px 2px !important;
        border: 0px !important;
    }

    .mw-150 {
        max-width: 150px;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    tr.active td {
        background-color: rgba(33, 150, 243, 0.18) !important;
    }
    .poiZoneMapViewSize {
        position: relative;
        width: 100%;
        height: calc(100vh - 148px);
    }

    .control-gray-ddl option {
        color: #333;
    }

    .display-block {
        display: block;
    }

    .display-none {
        display: none;
    }

    .collapsedBtnPOI {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .btn_switch_grp .btn_switch {
        border-radius: 50px;
        border: 1px solid #2196F3;
    }

        .btn_switch_grp .btn_switch.active,
        .btn_switch_grp .btn_switch:hover {
            background-color: #2196F3;
            color: #fff;
        }

    .map-panel-control {
        right: 10px;
        bottom: 63px;
    }

    .mapOptionsDashboard .btn-default {
        width: 41px;
        height: 40px;
    }

    .isFullScreen #rightDivHeight {
        width: 100% !important;
        float: left;
    }

    .isFullScreen #leftDivList {
        width: 0px !important;
        float: left;
        display: none !important;
    }

    .POIZone-panel-body #rightDivHeight {
        width: calc(100% - 370px);
        float: left;
    }


    .POIZone-panel-body #leftDivList {
        width: 370px;
        float: left;
    }
</style>
