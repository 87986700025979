<script setup>
    import {onMounted,ref,computed } from 'vue';
    import Fetch from '../classes/Fetch';
    import Cookie from '../classes/Cookie';
    import DateTime from '../classes/DateTime';
    import App from '../classes/App';
    import Header from "./Header.vue";
    import { useRoute,useRouter } from 'vue-router';

    var prenom=ref(null),nom=ref(null),imei=ref("359633107678885"),type=ref(null),immat=ref(null),sim=ref(null),consommation=ref(null),modelVehicule=ref(null),typeVehicule=ref(null),note=ref(null),relay=ref(null),infoUser=ref({}),page="editdevice",token=Cookie.getCookie("token"),route = useRoute(),router= useRouter();
    onMounted(() => {
        if (!token) {
            router.push({
                name: 'Login',
                // query: { kisko:2  },
            })
            return
        }
        Fetch.post("/getDevice",{imei:imei.value,token:token}).then((data)=>{
            if (data) {
                prenom.value=data.driver.prenom,nom.value=data.driver.nom,type.value=data.type,immat.value=data.immat,sim.value=data.sim,consommation.value=data.consommation,modelVehicule.value=data.modelVehicule,typeVehicule.value=data.typeVehicule,note.value=data.note,relay.value=data.relay;
            }
        }).catch((err)=>{console.log(err);})
    });
   function saveDevice() {
    var data={driver:{prenom:prenom.value??undefined,nom:nom.value??undefined},imei:imei.value??undefined,type:type.value??undefined,immat:immat.value??undefined,sim:sim.value??undefined,consommation:consommation.value??undefined,modelVehicule:modelVehicule.value??undefined,typeVehicule:typeVehicule.value??undefined,note:note.value??undefined,relay:relay.value??undefined};
    console.log(data);
    Fetch.post("/saveDevice",{data:data,token:token}).then((data)=>{
       console.log(data);
    }).catch((err)=>{console.log(err);})
   }
</script>
<template>
<div>
    <Header :infoUser="infoUser" :page="page"></Header>
    <div class="container" style="margin-top: 100px;">
        <form>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="validationServer01">Prénom</label>
                    <input type="text" class="form-control is-valid" id="validationServer01" placeholder="Prénom" v-model="prenom" required>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServer02">Nom</label>
                    <input type="text" class="form-control is-valid" id="validationServer02" placeholder="Nom" v-model="nom" required>
               
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServerUsername">Immatriculation</label>
                    <input type="text" class="form-control is-invalid" id="validationServerUsername" placeholder="Immatriculation" v-model="immat" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="validationServer03">Sim</label>
                <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Sim" v-model="sim" required>
               
                </div>
                <div class="col-md-3 mb-3">
                <label for="validationServer04">Imei</label>
                <input type="text" class="form-control is-invalid" id="validationServer04" placeholder="Imei" v-model="imei" required>
              
                </div>
                <div class="col-md-3 mb-3">
                    <label for="validationServer05">Type</label>
                    <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Type" v-model="type" required>
                
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="validationServer03">typeVehicule</label>
                <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="type Véhicule" v-model="typeVehicule" required>
               
                </div>
                <div class="col-md-3 mb-3">
                <label for="validationServer04">Relay</label>
                <input type="text" class="form-control is-invalid" id="validationServer04" placeholder="State" v-model="relay" required>
              
                </div>
                <div class="col-md-3 mb-3">
                    <label for="validationServer05">modelVehicule</label>
                    <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="model du Véhicule" v-model="modelVehicule" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-8 mb-3">
                    <label for="validationServer03">Note</label>
                    <textarea class="form-control" placeholder="Ajouter une note" v-model="note"></textarea>
                
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServer105">Consommation</label>
                    <input type="text" class="form-control is-invalid" id="validationServer105" placeholder="Consommation au 100" v-model="consommation" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-10">
                    <!-- <label for="validationServer05">Consommation</label>
                    <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="model du Véhicule" v-model="consommation" required> -->
                </div>
                <div class="col-md-2 mb-3">
                    <button class="btn btn-primary" @click="saveDevice()" type="button">Submit form</button>
                </div>
            </div>
        </form>
        
    </div>
</div>
</template>
