<script setup>
        import 'ol/ol.css';
        import Feature from 'ol/Feature';
        import Map from 'ol/Map';
        import Point from 'ol/geom/Point';
        import Polyline from 'ol/format/Polyline';
        import VectorSource from 'ol/source/Vector';
        import View from 'ol/View';
        import {
        Circle as CircleStyle,
        Fill,
        Icon,
        Stroke,
        Style,
        } from 'ol/style';
        import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
        import {getVectorContext} from 'ol/render';
        import TileImage from 'ol/source/TileImage';
        import { defaults as defaultControls, OverviewMap } from "ol/control";
        import FullScreen from 'ol/control/FullScreen';
        import {createEmpty} from 'ol/extent';
        import Overlay from 'ol/Overlay';
        import {extend} from 'ol/extent';
        import {getTransform,fromLonLat,transform} from 'ol/proj';
        import Text from 'ol/style/Text';
        import * as olTransform from 'ol/transform';
        import LineString from 'ol/geom/LineString';
        import Draw from 'ol/interaction/Draw';
        import Snap from 'ol/interaction/Snap';
        import {Circle,Polygon } from 'ol/geom';
        import Modify from 'ol/interaction/Modify';
        import Collection from 'ol/Collection';
        import Translate from 'ol/interaction/Translate';
        import moment from 'moment'
        import store from '../store'
        import {onMounted,ref,computed } from 'vue'
        import Fetch from '../classes/Fetch'
        import Cookie from '../classes/Cookie'
        import DateTime from '../classes/DateTime';
        import momentLang from '../classes/momentLang'
        import Header from "./Header.vue";
        // import signalBar from "./signalBar.vue";
        import signalGSM from "./signalGSM.vue";


        var token=Cookie.getCookie("token"),contentPopup,markerPopup,devices=ref([]),page="geofence",
            datasImeis=[],activeHistory=ref([]),totalDistance=ref(0),vitesseMax=ref(0),selectedDevice=ref({}),
            devicesById=ref([]),dataTable=null,listPoi=ref([]),listGeo=ref([]),historyData=ref([]),totalTrajets=ref(0),refStartTime=ref(null),
            map=null,activityToday=ref(0),activity24=ref(0),activity48=ref(0),infoUser=ref({}),alarmesData=ref([]),currentZoneName=ref(null),
            totalStatus=[],totalMouve=ref(0),totalStatic=ref(0),totalOffline=ref(0),totalOffline48=ref(0),socket=null,listCommands=null,
            compteurTrajet=0,coords=null,draw=null, snap=null,createdZone=null,radius=null,dtype=null,zoneCoordonnates=[],
            AllCheckedGeoLines=0,selectedIdGeo=null,numeroPoi=0,numeroGeo=0,modifyGeo=null,geoToEditId=null,map= null,user=ref({}),popup=null,currentLimitVitesse=ref({}),
            // computed =======================================================================================================
        //     devices=computed(()=> {
        //         var elements=JSON.parse(JSON.stringify({...devicesById.value}));
        //   return elements != null?elements:[];}),
          
          devicesLength=computed(()=> {
            activityToday.value=0;activity24.value=0,activity48.value=0;
             var statuts={...activeHistory.value};
             statuts=Object.values(statuts);
                statuts.forEach((value,index)=>{
                    if (value==1) { activityToday.value++;   }else if (value==2) { activity24.value++;   }if (value==3) { activity48.value++;   }
                });
               return statuts.length;
            });

            function setActivityDuration(duration,status) {
                if (status==1) {
                    totalMouve.value+=duration;
                }else if (status==2) {
                    totalStatic.value+=duration;
                }else{
                    totalOffline.value+=duration;
                }
            }
            momentLang.setLangFr(moment);
            // momentLang.setLangEn(moment);
        var layers = [];
        const props=defineProps({
          kisko:{}
        });
        var map_image_sat = new TileLayer({
          visible: true,
          source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=y@113&hl=fr&&x={x}&y={y}&z={z}' })
        });
        var map_image_plan = new TileLayer({
          visible: false,
          source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=m@113&hl=fr&&x={x}&y={y}&z={z}' }),
        });
        const center = [-17.2529206,14.762637];
        layers.push(map_image_sat);
        layers.push(map_image_plan);
        var olview = new View({
          center: fromLonLat(center),
          zoom: 11,
          minZoom: 4,
          maxZoom: 20
        });
        
        var vectorSource_poi = new VectorSource({});
        var vectorSource_geo = new VectorSource({});
        var poiLayer = new VectorLayer({
          source: vectorSource_poi
        });
        var vectorLayerGeo = new VectorLayer({
          source: vectorSource_geo
        });
        var extent = createEmpty();
        function changeMap(style,el) {
          if ($(el).hasClass("Select")) { return; }
          $(".MapTypeButton").find(".Active").removeClass("Select");
          if (style == "sat") {
              $(".MapTypeButton").find(".sat").addClass("Select");
              map_image_plan.setVisible(false);
              map_image_sat.setVisible(true);
          } else {
              $(".MapTypeButton").find(".plan").addClass("Select");
              map_image_sat.setVisible(false);
              map_image_plan.setVisible(true);
          }
        }
        
        function extentVector(Layer) {
          extend(extent, Layer.getSource().getExtent());
          map.getView().fit(extent, map.getSize());
          map.getView().setZoom(map.getView().getZoom() - 1);
        }
        //-=================================================
        var vectorSource = new VectorSource({});
        var vectorLayer = new VectorLayer({
          source: vectorSource
        });
        var sourceHistory = new VectorSource({});
        var vectorHistory = new VectorLayer({
          source: sourceHistory
        });
        var idPoi=0;
        var createPoiEnabled=false;
        var iconPoi="images/icons/airplane.png";
        var iconFeature=null,poiCoords;
        
        
        function sortDevicesList(){
          var selfEl = $(".devicesListContainer");
           var sortedList = $('.DeviceListRow', selfEl).sort(function(lhs, rhs){
               return parseInt($(lhs).attr("data-order"),10) - parseInt($(rhs).attr("data-order"),10);
           });
           selfEl.append(sortedList);
        }
        function getAdresse(lat,lng,index,callback) {
            fetch("https://server.sunutrack.com:8443/nominatim?lat="+lat+"&lon="+lng).then(function(response) {return response.json()}).then(function(data) {
              callback(data,index);
          });
        }
        
        function searchAdress(q,callback) {
          fetch("https://server.sunutrack.com:8443/search?q="+q+"&format=json&polygon_geojson=1&addressdetails=1").then(function(response) {return response.json()}).then(function(data) {
              //callback(data);
          })
        }
        function getFeature(id) {
          return vectorSource.getFeatureById(id);
        }

        function getFeatureHistory(id) {
          return sourceHistory.getFeatureById(id);
        }
        function showHideLine(id,action) {
          var feature = getFeatureHistory(id);
          if (!feature) {return;}
          if (action==1) {
              var vitesse=feature.get("vitesse");
              var colorTrace = colorTracer(vitesse);
              var lineStyle = new Style({
                  stroke: new Stroke({
                      color: colorTrace,
                      width: 4,
                      zIndex: 500
                  })
              });
              feature.setStyle(lineStyle); return;
          }
          feature.setStyle(new Style({}));
        }
        
       
 
        function activeDataTableAlarme() {
          if($.fn.dataTable.isDataTable('#alarmesTab')){
              alarmesDataTable.destroy();
          } 
          alarmesDataTable= $('#alarmesTab').DataTable( { "searching": false, "bPaginate": false,"info": false,"scrollY": 333 });
        }
        
        //==============================================
        function hideSowProfile(){
            $(".profileDropdown").toggle(); 
        }
        function ToggleAboutPopup(){
            $(".aboutPopup").toggle(); 
        }
        function ToggleFournisseurPopup(){
            $(".fournisseurPopup").toggle(); 
        }
        function getNameInput() {
           return  $("#poiname").val();    
        }
       
        var hStart=null,hEnd=null;
        
          function formatHDate(date){
              var fD=date.split("/");
              var time=fD[2].split(" ")[1]+":00";
              return fD[2].split(" ")[0]+"-"+fD[1]+"-"+fD[0]+" "+time;
          }
          // jQuery('.timepicker').datetimepicker({
          //         datepicker:false,
          //         format:'H:i',
          //         onChangeTime(dp,$input){
          //                 console.log($input.val())
          //         }
          // });
        function showListDevices() {
                if ($('.listDevices:hover').length == 0) {
                  $(".listDevices").toggle();  
                }
        }
        function cancelGeoDraw() {
        //   $('.addGeoMoadal').hide(); 
          if(draw){
              map.removeInteraction(draw);
              map.removeInteraction(snap);
              if(createdZone){vectorSource_geo.removeFeature(createdZone);}
          }   
          map.removeInteraction(modifyGeo);
        }
        function showGeoModal(indice) {
          $('.addGeoMoadal').show(); 
          map.setTarget("mapGeo");
          map.updateSize();  
          if(indice==2){createGeoZone();indice=3;}
          changeGeoModalValues("","",indice);
        }
        function changeZoneGeoType(type,element) {
          $(element).addClass("ivu-select-item-selected");
          if (type=="Circle") {
                  $(".polygoneType").removeClass("ivu-select-item-selected");
              $(".type_zone").find(".selecredTypeZone").removeClass("fa-hexagon").addClass("fa-circle");     
          }else{
                $(".type_zone").find(".selecredTypeZone").removeClass("fa-circle").addClass("fa-hexagon"); 
                $(".circleType").removeClass("ivu-select-item-selected");
          }
          $(".zoneTypeListe").toggle();
          createGeoZone();
        }
        jQuery.datetimepicker.setLocale('fr');
        
        
        var selectedImei=null;
        function sowListPopup() {
            if(!selectedImei){WarningToastr("Vous devez sélectionné un appareil");return;}
          var element=$("#popupButton"+selectedImei);
          var offset = element.offset();
          var popupEl=$(".listMoreInfosPopup");
          var topPopup=offset.top - popupEl.innerHeight();
          topPopup=topPopup<5?5:topPopup;
          popupEl.fadeIn().css({
              left: Math.min(offset.left+element.innerWidth(), $(window).innerWidth()-popupEl.outerWidth()),
              top: topPopup
              });
          }
        function hideShowUserInfo(){
          $(".UserInfoEdit").toggle();
        }
        function hideShowLangPopup(){
          $(".ListRowIconNoMargin").toggle();
        }
        function sortByClass(a, b) {
          return a.className < b.className;
        }
        function showHideProfile(){
               $(".profileDropdown").toggle();   
          }
          function filterStatut(statut,element){
                  $(".statutBarCountainer").find(".ivu-tabs-tab").removeClass("ivu-tabs-tab-active");
                  $(element).addClass("ivu-tabs-tab-active");
                  if(statut==0){
                      showHideAllDevicesList(0);return;
                  }
                  showHideAllDevicesList(1);
                  if(statut==1){
                      $(".listStatut1").show();
                      $(".listStatut2").show();
                  }else{
                      $(".listStatut3").show();
                      $(".listStatut4").show();
                  }
          }
          var statutBarPositon=0;
          function nextStatutBar(){
             statutBarPositon -= 40;    
             statutBarPositon= statutBarPositon<-90?-90:statutBarPositon;
             $(".statutBarCountainer").css("transform","translateX("+statutBarPositon+"px)");
          }
          function prevStatutBar(){
             statutBarPositon += 40;    
             statutBarPositon= statutBarPositon>0?0:statutBarPositon;
             $(".statutBarCountainer").css("transform","translateX("+statutBarPositon+"px)");
          }
          
          function hideShowCmdList(){
              hideAllPanels();
              listCommands.toggle();
          }
        function hideAllPanels(){
          var elements =$(".leftPanelManager");
          elements.find(".ivu-drawer").removeClass("move-right-enter-active").addClass("move-right-leave-active move-right-leave-to");
          elements.addClass("ivu-drawer-hidden");
          elements.find(".ivu-drawer").hide();
         
          map.setTarget("gMapView");
          map.updateSize(); 
          createPoiEnabled=false;   
        } 
        
        function logout(){
          $("#logoutBtn").click();
        } 
        var listDeviceRow=$(".DeviceList").find(".DeviceListRow");
        listDeviceRow.click(function(ev){
          listDeviceRow.removeClass("Select");
          $(ev.currentTarget).addClass("Select");
        }) 
        function showHideAllDevicesList(index){
          if(index==1){
              listDeviceRow.hide();return;
          }
          listDeviceRow.show();
        }
        
        //=================================
        function dashFlotteStatistiques(mouve,statique,offline,offline48){
        // $(".onlineCount").text(mouve+static+offline);
        $(".mouveCount").text(mouve);
        $(".staticCount").text(statique);
        $(".hlCount").text(offline);
        $(".hl24Count").text(offline48);
        }
        function generatePie(mouve,statique,offline,offline48){
        Highcharts.chart('chart-fleet', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: null
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '{point.name}: <b style="color:{point.color}">{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
              valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
              //allowPointSelect: true,
              cursor: 'pointer',
              //size:"25%",
              dataLabels: {
                  enabled: false,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
          }
        },
        series: [{
          name: 'Status',
          colorByPoint: false,
          data: [{
              name: 'Mouvement',
              y: mouve,
              color:"#378124"
          }, {
              name: 'Statique',
              y: statique,
              color:"#ff9600"
          }, {
              name: 'H-ligne -48',
              y: offline,
              color:"#e14c4c"
          }, {
              name: 'H-ligne +48',
              y: offline48,
              color:"#706f6f"
          }]
        }],
        exporting:{
          enabled:false
        }
        });
        dashFlotteStatistiques(mouve,statique,offline,offline48);
        }
        //=======================================================
        function toggleProgessBar(){
        $(".progressBarMoadal").toggle();
        }
        function filter(element) {
          var value = $(element).val().toLowerCase();
          $(".listdevicesHistorique li").each(function() {
              if ($(this).text().toLowerCase().search(value) > -1) {
                  $(this).show();
              }
              else {
                  $(this).hide();
              }
          });
        }
        var selectedImeiHistory=null;
        function selectedDeviceHistory(imei,el){
          selectedImeiHistory=imei;
          listItemToggle(el);
        }
        var selectedImeiRapport=null;
        function selectedDeviceRapport(imei){
          selectedImeiRapport=imei;
        }
    function selectUser(id) {
        $("#"+id).prop('checked', true);
    }
    function ChangeAutoDate(value,unite, obj) {
        var now=new Date();
        $(".btn-day").removeClass("btn-background-cyan");
        if (unite=="H") { 
            var dateEnd= moment(now).format("YYYY-MM-DD HH:mm")+":59";
            var localeEnd=moment(now).format("DD-MM-YYYY HH:mm");
            var start=DateTime.addHours(value,now);
            $("#btnHistoryHour").addClass("btn-background-cyan");
            $("#HistoryHourSpan").html(obj);
        
        var dateStart= moment(start).format("YYYY-MM-DD HH:mm:ss");
            var localeStart=moment(start).format("DD-MM-YYYY HH:mm");
        }else{ 
            var dateEnd= moment(now).format("YYYY-MM-DD")+" 23:59:59";
            var localeEnd=moment(now).format("DD-MM-YYYY")+" 23:59";
            var start=DateTime.addDays(value,now); 
            $(obj.target).addClass("btn-background-cyan");
            var dateStart= moment(start).format("YYYY-MM-DD")+" 00:00:00";
            var localeStart=moment(start).format("DD-MM-YYYY")+" 00:00";
        }

        $("#TrackingHistoryFromDate").val(dateStart);
        $("#TrackingHistoryToDate").val(dateEnd);
        //$("#FromDate").val(data.fromDate);
        //$("#ToDate").val(data.toDate);
        $('#daterange-history span').html(localeStart + ' - ' + localeEnd);
    }
    function selectDevice(index) {
        selectedDevice.value=devices.value[index];
    }

        

        // ================================================================
        function addMarker(datas,nowServerDate) {
            datasImeis=datas;
            $(".totalDevices").text(datas.length);
            totalStatus[1]=0,totalStatus[2]=0,totalStatus[3]=0,totalStatus[4]=0;
            datas.forEach(function(data,index) {
                var position =data.position;
                var name = data.driver,
                immat = data.immat,
                driver=name.prenom+" "+name.nom,
                imei=data.imei,
                speed=position.speed,
                tensionExt=position.tensionExt,
                FuelP=position.FuelP??0,
                acc=position.ACC,
                angle=position.angle,
                typeVehicule=data.typeVehicule,
                adress=data.adresse,blocked=position.Blocked,
                gpsQuantity=position.gpsQuantity,
                GsmSignal=position.GsmSignal,precision=position.precision,
                dataTime=position.date,lng=position.lng,lat=position.lat;
                var coordonnees = fromLonLat([lng, lat]),
                geometry = new Point(coordonnees);
                //geometry.setCoordinates(coordonnees);
                var signalGsm=Math.round(((GsmSignal/31)/20)*100),precisionGps=parseInt(precision);
                devices[imei]=data;
                var marker = new Feature();
                marker.setId(imei);
                marker.set('type', 'marker');
                marker.set('driver', driver);
                marker.set('speed', speed);
                marker.set('blocked', blocked);
                marker.set('utc', dataTime);
                marker.set('battery_ext',tensionExt);
                marker.set('fuel', FuelP);
                marker.set('immat', immat);
                marker.set("signalGsm",signalGsm);
                marker.set("typeVehicule",typeVehicule);
                marker.set("precision",precisionGps);
                vectorSource.addFeature(marker);
                marker.setGeometry(geometry);
                var status = getStatus(dataTime, nowServerDate, speed, acc,imei);
                marker.set('statut', status);
                marker.set("adresse",null);
                marker.set("lat",lat);marker.set("lng",lng);
                marker.setStyle(iconStyle(status,typeVehicule,name.prenom,immat,angle));
                data.status=status;
                devicesById.value[imei]=data;
            });
        }
        function dateDiff (date1, date2) {
          var diff = {};var tmp = date2 - date1;
          tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
          diff.sec = tmp % 60; // Extraction du nombre de secondes
        
          tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie enti?)
          diff.min = tmp % 60; // Extraction du nombre de minutes
        
          tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (enti?s)
          diff.hour = tmp % 24; // Extraction du nombre d'heures
          tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
          diff.day = tmp;
        
          return diff;
        }
        
        function convertDate (date) {
          var myRegEx = new RegExp("-", "gm");
          date = date.replace(myRegEx, "-");
          date = date.replace(/ /, "T");
          date = new Date(date);
          return date;
        }
        function getStatus(localtimeUser, localTimeNow, vitesse, acc,imei) {
          var status = 3;
          var diff = dateDiff(convertDate(localtimeUser), convertDate(localTimeNow));
              if (diff.day < 1) {
                  if (acc == 0) {
                      status = 3;
                  }
                  if (diff.hour < 1) {
                      if (diff.min <= 10) {
                          if (vitesse <1) {
                              status = 2;
                              if (acc == 0) {
                                  status = 3;
                              }
                          } else {
                              if (acc == 1) {
                                  status = 1;
                              }
                          }
                      } else {
                          status = 3;
                      }
                  } else {
                      status = 3;
                  }
              } else {
                  status = 3;
                  if (diff.day >= 2) {
                      status = 4;
                  }
              }
          return status;
        }
        function iconStyle(status,type ,driver,immat=null,angle) { 
          var icon="images/rt/ic_"+type+status+".png";
         var scale=type=="moto"?0.7:0.46;
         var offsetY=type=="moto"?40:32;
          var text2="";
          
          if (immat !="") {
              text2="("+immat+")";
          }
          var canvas = document.createElement('canvas');
          var font = '14px "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif';
          var font2 = 'italic 11px Calibri,sans-serif';
          // var context = canvas.getContext('2d');
          // context.font = font;
          // ================================
         var iconStyle = new Style({
              image: new Icon({
                  anchor: [0.5, 0.5],
                  anchorOrigin: 'top-left',
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  opacity: 1,
                  src:icon,
                  fill: "#009688",
                  scale:scale,
                  rotation: 0.017453292 * angle,
              }),
              text: new Text({
                  offsetY: offsetY,
                  // offsetX: -width2/2,
                  font: font,
                  fill: new Fill({ color: '#225588' }),
                  backgroundFill: new Fill({ color: '#FFFFCC' }),
                  backgroundStroke: new Stroke({
                      miterLimit: 0,
                      color:"#FF9933"
                  }),
                  padding: [2, 2, 2, 2],
                  text: driver+"***" ,//$('#gbVar').attr('login')
              })
          });
         
          iconStyle.setZIndex(zIndexLine(status));
          var style=[];style[0]=iconStyle;
          //style[1]=iconStyle2;
          return style;
        }
        function zIndexLine (status) {
          var z = 0;
          if (status == 1) z = 4000;
          if (status == 2) z = 3000;
          if (status == 3) z = 2000;
          if (status == 4) z = 1000;
          return z;
        }
        function setAdresse(address,index) {
            historyData.value[index].adresse=address;
        }
        function defineLineStatut (status,imei,isUpdate,oldStatus) {
            totalStatus[status]++;
            $("#listRow"+imei).attr("data-order",status);
            var statutText="H-ligne +48h"; if (status==1) { statutText="Mouvement"; }else if (status==2) { statutText="Statique"; }else if (status==3) { statutText="H-ligne -48h"; }
                $(".statut_" + imei).html('<font class="status" style="color:'+getStatutColor(status)+';">'+statutText+'</font>');
            $("#listRow"+imei).attr("class","DeviceListRow SBorderB HDiv listStatut"+status);
            if(isUpdate){
                totalStatus[oldStatus]--;sortDevicesList();
                if (totalStatus[1] != totalMouve.value || totalStatus[2] !=totalStatic.value || totalStatus[3] !=totalOffline.value || totalStatus[4] !=totalOffline48.value) {
                    generatePie(totalStatus[1],totalStatus[2],totalStatus[3],totalStatus[4]);
                }
            }
            totalMouve.value=totalStatus[1],totalStatic.value=totalStatus[2],totalOffline.value=totalStatus[3],totalOffline48.value=totalStatus[4];
            $(".onlineCount").text(totalStatus[1]+totalStatus[2]);
            $(".offlineCount").text(totalStatus[3]+totalStatus[4]);
        }
 
        
     

        function closer() {
          popup.set('imei', null);
          $(popup.element).hide();
        }
        
        function getFeatureGeo (id) {
         return vectorSource_geo.getFeatureById(id);
        }
        function togleAlarmPanel () {
          var element=$("#ID_ALARM_MANAGE").find(".ivu-drawer-wrap");
          hideShowPanel(element,0);
        }
        function toglePoiPanel () {
          var element=$(".POIManage").find(".ivu-drawer-wrap");
          hideShowPanel(element,1);
        }
        function togleJournalPanel () {
          var element=$(".JournalManager").find(".ivu-drawer-wrap");
          hideShowPanel(element,0);
        }
        function hideShowPanel (element,origine) {
          hideAllPanels(origine);
          if (element.is(":hidden")) {
              element.removeClass("ivu-drawer-hidden");
              element.find(".ivu-drawer").show();
              element.find(".ivu-drawer").removeClass("move-right-leave-active move-right-leave-to").addClass("move-right-enter-active");
                if (origine==1) {
                  map.setTarget("mapPoi");
                  map.updateSize();   
                  createPoiEnabled=true;
                }
          }else{
              element.find(".ivu-drawer").removeClass("move-right-enter-active").addClass("move-right-leave-active move-right-leave-to");
              element.addClass("ivu-drawer-hidden");
              element.find(".ivu-drawer").hide();
              if (origine==1) {
                  map.setTarget("gMapView");
                  map.updateSize(); 
                  createPoiEnabled=false;    
                }
          }
        }
        var geoType="Polygon";
        // global so we can remove them later
        function createGeoZone() {
          geoToEditId=null;zoneCoordonnates=[];
          $("#mapviewsidebarpoi").trigger("click");
          map.removeInteraction(draw);
          map.removeInteraction(snap);
          draw = new Draw({
              source: vectorSource_geo,
              type: geoType
          });
          map.addInteraction(draw);
          snap = new Snap({source: vectorSource_geo});
          map.addInteraction(snap);
          draw.on('drawend', function(evt){
              createdZone = evt.feature;
              coords = createdZone.getGeometry().getCoordinates()[0];
              coords.forEach(function(data,index){
                  var lngLat=transform(data, 'EPSG:3857', 'EPSG:4326');
                   zoneCoordonnates.push(lngLat); });
              dtype=createdZone.getGeometry().getType();
              radius=0;
              if (dtype=="Circle") {
                  radius=createdZone.getGeometry().getRadius();
              }
              map.removeInteraction(draw);
              map.removeInteraction(snap);
              //displayGeoOverlay(coords);
        
          });
        }
        function afficheZoneGEO (coords,id,type,radius,active,visibility) {
          if (!coords) {return;}
          var geofence = new Feature();
          geofence.setId(id);
          if (type=="Circle") {
              geofence = new Feature(new Circle(coords, radius));
          }else{
              var polygonGEO = new Polygon([coords]);
              geofence.setGeometry(polygonGEO);
          }
          if (visibility == 0) {
                var geoStyle = new Style({
                    stroke: new Stroke({
                        color: 'rgba(127, 127, 127,0.8)'
                    })
                });
                geofence.setStyle(geoStyle);
            } else {
                var geoStyle = new Style({
                    stroke: new Stroke({
                        color: '#00AAFF',
                        width: 2
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255,0.2)'
                    })
                });
                geofence.setStyle(geoStyle);
            }
          vectorSource_geo.addFeature(geofence);
        }
        function saveZone () {
          var vitesse=$("#createdGeoLimitVitesse").val().trim();
          var name=$("#createdGeoName").val().trim(),limitVitess=vitesse!=""?parseInt(vitesse):0,desc=$("#createdGeoDesc").val().trim();
          if (!name) { ErrorToastr("Vous devez entrer un nom pour la zone");return; }
          if (!coords) { WarningToastr("Vous devez tracer la zone");return; }
          var body = {coords:coords,type:dtype,radius:radius,name:name,speed:limitVitess,idZone:geoToEditId,coordinates:zoneCoordonnates,token:token,description:desc};
          $("#mapviewsidebarpoi").trigger("click");
          Fetch.post("/saveGeo",body).then(function(data) {
            if (data.success) {
                var idSavedZone=data.id;
                afficheZoneGEO(coords,idSavedZone,dtype,radius);
                SuccessToastr("Zone Ajoutée avec succès!");
                body._id=idSavedZone;
                listGeoArray.push(body);
                coords=null;
                // listGeo.value=listGeoArray;
                getGeo();
                // console.log(listGeoArray);
            }else{
                ErrorToastr("Une erreur inatendue s'est produite.");
            }
            //   if (!geoToEditId) { numeroGeo++; addGeoLine(idSavedZone,name,dtype,limitVitess);
            //  }else{
            //       $("#name"+geoToEditId).text(name);SuccessToastr("Zone Modifiée avec succès!");}
              console.log(idSavedZone);
              cancelGeoDraw();
          }); 
        }
        function getPoi() {
          Fetch.post("/getPoi",{token:token}).then(function(datas) {
              numeroPoi=0;
              listPoi.value=datas;
              datas.forEach(function(data) {
                  var coords=data.coords,icon=data.icon,name=data.name,id=data._id;
                  placePoi(coords,icon,name,id);
              });
          }); 
        }
        var listGeoArray=[];
        function getGeo() {
            Fetch.post("/getGeo",{token:token}).then(function(datas) {
            listGeoArray=datas;
            listGeo.value=datas;
              numeroGeo=0;
              datas.forEach(function(data) {
                  numeroGeo++;
                  var coords=data.coords,type=data.type,name=data.name,id=data._id,radius=data.radius,speed=data.speed;
                  afficheZoneGEO(coords,id,type,radius);
                //   addGeoLine(id,name,type,speed);
              });
              extentVector(vectorLayerGeo);
             // $('.geofencesTabs').DataTable( { "searching": false, "bPaginate": false,"info": false,"scrollY": 'auto' });
          }); 
        }
        function unSelectAllDevicesGeo () {
          $(".listeGeoDevices").find(".devicelineCheckbox").removeClass("ivu-checkbox-checked");
          $(".listeGeoDevices").find("input").prop('checked', false);
        }
        function getGeoById (id) {
          var body = {id:id};
          fetch("getGeoById",{method:"POST",body: JSON.stringify(body), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.json() }).then(function(datas) {
              unSelectAllDevicesGeo();
             var data =datas[0];
             var imeis=data.imeis;
             if (!imeis) {return;}
             var keys=Object.keys(imeis);
             keys.forEach(function(imei){
                 var code =imeis[imei].code;
                 var el=$("#deviceGeoRow"+imei);
                 var inputIn = el.find(".inCheckBox");
                 var inputOut =el.find(".outCheckBox");
                 if (code==3) { inputIn.prop('checked', true);inputOut.prop('checked', true);el.find(".devicelineCheckbox").addClass("ivu-checkbox-checked") }else if (code==1) {
                  inputIn.prop('checked', true); el.find(".inCheckBoxSelect").addClass("ivu-checkbox-checked"); }else if (code==2) { 
                      inputOut.prop('checked', true);el.find(".outCheckBoxSelect").addClass("ivu-checkbox-checked");}
             })
          }); 
        }
        function placePoi (coords,icon,name,id) {
          numeroPoi++;
          var geometry=new Point(coords);
          var iconStyle1 = new Style({
              image: new Icon( /** @type {olx.style.IconOptions} */ ({
                  anchor: [0.5, 46],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  src: icon,
                  scale:0.7
              }))
          });
          var feature = new Feature({
              name: 'poi'
          });
          feature.setId(id);
          feature.setGeometry(geometry);
          feature.setStyle(iconStyle1);
          vectorSource_poi.addFeature(feature);
        //   addPoiLine(id,name,icon);
        }
   
        
        function setSelectedIdGeo (id) {
          selectedIdGeo=id;
          $(".listeGeofences").find("td").css("background","none");
          $("#geo"+id).find("td").css("background-color","#c9eafc");
          getGeoById(id);
        }
        
        function selectGeoLine (el) {
         var input= $(el).toggleClass("ivu-checkbox-checked").find(".geolineCheckbox");
         var checked=input.is(':checked');
         console.log(countSelectedGeoLine()+" "+numeroGeo);
         if(checked){ if (countSelectedGeoLine()==numeroGeo) {var input=$(".selectAllGeoLineLable").addClass("ivu-checkbox-checked").find("input");input.prop('checked', true);} }else{
             var input=$(".selectAllGeoLineLable").removeClass("ivu-checkbox-checked").find("input");input.prop('checked', false);}
        }
        function selectDevicesLines (el) {
          var input= $(el).toggleClass("ivu-checkbox-checked").find(".devicelineCheckbox");
          // var checked=input.is(':checked');
          // console.log(countSelectedGeoLine()+" "+numeroGeo);
          // if(!checked){ var input=$(".selectAllGeoLineLable").removeClass("ivu-checkbox-checked").find("input");input.prop('checked', false);}
        }
        function assocGeoDevice (imei) {
          if (!selectedIdGeo) {WarningToastr("Vous devez sélectionner une zone !"); return;}
          var device ={...devices.value[imei]};
            var alarme=0;
            device.active=device.active?1:0;
            if (device.enter && device.leave) {alarme=3}else if (device.enter) { alarme=1; }else if (device.leave) {alarme=2}
            imeiGeoAssoc(selectedIdGeo,imei,alarme,device.speed,device.active,1);
        }
        function toggleAssocGeoAllDevice (code) {
          if (!selectedIdGeo) {WarningToastr("Vous devez sélectionner une zone !"); return;}
          var el1= $("#geo"+selectedIdGeo);
          var geoLimitSpeed=el1.find('.geoLimitSpeed').val();
          Object.keys(devices).forEach(function(imei){
              imeiGeoAssoc(selectedIdGeo,imei,code,geoLimitSpeed,1,0);
              var el= $("#deviceGeoRow"+imei);
              if (code==3) {
                  var input= el.find(".devicelineCheckbox").addClass("ivu-checkbox-checked").find(".geoCheckBox");
                  input.prop('checked', true);
              }else{
                  var input= el.find(".devicelineCheckbox").removeClass("ivu-checkbox-checked").find(".geoCheckBox");
                  input.prop('checked', false); 
              }
          });
          SuccessToastr("Opération lancé avec succès.");
        }
        function selectDeviceLine (el) {
          var input= $(el).toggleClass("ivu-checkbox-checked").find(".geoCheckBox");
          var checked=input.is(':checked');
          if (checked) { input.prop('checked', false); }else{input.prop('checked', true);}
        }
        function countSelectedGeoLine () {
          var trs=$(".listeGeofences").find("tr");
          var count=0;
          trs.each(function(index,element){
              var checked= $(element).find(".geolineCheckbox").is(':checked');
              if (checked) { count++; }
          });
          return count;
        }
        function selectAllGeoLine () {
          var input=$(".selectAllGeoLineLable").toggleClass("ivu-checkbox-checked").find("input");
          var trs=$(".listeGeofences").find("tr")
          if (input.is(':checked')) {
              trs.each(function(index,element){
                  var trInput=$(element).find(".geolineCheckbox");
                  if (!trInput.is(':checked')) {
                      trInput.prop('checked', true);
                      $(element).find(".checkBoxSpan").addClass("ivu-checkbox-checked"); 
                  }
                  
              });
          }else{
              trs.each(function(index,element){
                  var trInput=$(element).find(".geolineCheckbox");
                  if (trInput.is(':checked')) {
                      trInput.prop('checked', false);
                      $(element).find(".checkBoxSpan").removeClass("ivu-checkbox-checked");
                  }
                 
              });
          }
        }
        function followPoi(id) {
          var feature = vectorSource_poi.getFeatureById(id);
          if (feature) {
              var coordinates = feature.getGeometry().getCoordinates();
              map.getView().setCenter(coordinates); map.getView().setZoom(17);   
          }
          $("#POIVehicleList_"+id).toggleClass("d-none");
        }
        function removePoi (id) {
          var data = {id:id};
          fetch("removePoi",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
            SuccessToastr("Le Poi a bien été supprimé!");
              
          });
        }
        function imeiGeoAssoc (id,imei,alarme,speed,active,origin) {
          var data = {id:id,imei:imei,alarme:alarme,speed:speed,active:active,token:token};
          Fetch.post("/assocGeo",data).then(function(data) {
              console.log(data);
              if (data.success&&origin) {
                SuccessToastr("Opération réussi!");
              }
          });
        }

        function removeGeo(id) {
            var data = {id:id,token:token};
            Fetch.post("/removeGeo",data).then(function(data) {
                // console.log(data);
                $("#geo"+id).remove();
                vectorSource_geo.removeFeature(getFeatureGeo(id))
            });
        }
        function changeGeoModalValues(name,limitVitesse,indice) {
          $("#createdGeoName").val(name);
          $("#createdGeoLimitVitesse").val(limitVitesse);
         if (indice==1) {
             $(".geoModalFooter").hide();
         } else if (indice==2){ $(".geoModalType").hide(); $("#saveZoneBtn").text("Modifier") }else{
          $(".geoModalType").show();$(".geoModalFooter").show();
         }
        }
        function editGeoZone(id,name,limitVitesse,desc) {
        //   showGeoModal(0);
            $("#createdGeoLimitVitesse").val(limitVitesse);
            $("#createdGeoName").val(name);
            $("#createdGeoDesc").val(desc);
            $("#mapviewsidebarpoi").trigger("click");
          geoToEditId=id;
        //   changeGeoModalValues(name,limitVitesse,2);
          modifyGeo= new Modify({source: vectorSource_geo});
          map.addInteraction(modifyGeo);
          zoomSelectedGeo(id);
          modifyGeo.on("modifyend",function(evt) {
              var feature =getFeatureGeo(id);
              coords=feature.getGeometry().getCoordinates()[0];
              zoneCoordonnates=[];
              coords.forEach(function(data,index){
                  var lngLat=transform(data, 'EPSG:3857', 'EPSG:4326');
                   zoneCoordonnates.push(lngLat); });
              //map.removeInteraction(modifyGeo);
            //  evt.features.forEach(function(feature,index){coords=feature.getGeometry().getCoordinates();
          })
        }
        var popup_immat=ref(""),popup_driver=ref(""),popup_statut=ref(""),
        popup_last_updated=ref(""),popup_utc=ref(""),popup_adresse=ref(""),popup_speed=ref(0),popup_fuel=ref(0),popup_distance=ref(0),popup_battery_ext=ref(0),popup_precision=ref(0),popup_signalGsm=ref(0),popup_status=ref(0),popup_status_color=ref(null),popup_blocked=ref(0);
        function followMarker(imei) {
            selectedImei=imei;
            $(popup.element).find(".speed-no").text(232);
            var feature = getFeature(imei);
            $(".DeviceName").text(feature.get("driver"));
            // if (!map.getOverlayById("popup")) { map.addOverlay(popup); }
            popup.set('imei', feature.getId());
            // markerPopup.style.display = "block";
            setPopupContent(feature);
            var coordinates = feature.getGeometry().getCoordinates();
            popup.setPosition(coordinates);
            $(popup.element).show();
            map.getView().setCenter(coordinates);
               
        }
        function followGeo(id) {
            zoomSelectedGeo(id);
            $("#TerritoryVehicleList_"+id).toggleClass("d-none");
        }
        function zoomSelectedGeo(id) {
          map.getView().fit(getFeatureGeo(id).getGeometry().getExtent(), map.getSize());
        }
        function savePoi () {
          var coordsPoi=iconFeature.getGeometry().getCoordinates(),name=getNameInput();
          if (!coordsPoi||!name.trim()) { WarningToastr("Vous devez créer un point avant de l'enregistré!"); return; }
          var data = {coords:coordsPoi,icon:iconPoi,name:name};
          $("#poiname").val("");
          fetch("savePoi",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
            SuccessToastr("Le Poi a bien été enregistrer!");
              numeroPoi++;
            //   addPoiLine(data,name,iconPoi);
          });
        }
        // =====================================================================================
        
          
        
        function createMap(target) {
            var mapDynamic = new Map({controls: defaultControls({ zoom : false }).extend([
                    new FullScreen()]),
                    layers: layers,
                    // Improve user experience by loading tiles while dragging/zooming. Will make
                    // zooming choppy on mobile or slow devices.
                    loadTilesWhileInteracting: true,
                    target:target,
                    view: olview
            }); 
            setTimeout(() => {
                mapDynamic.updateSize();
            }, 700);
            return mapDynamic;   
        }
        var startButton = document.getElementById('animateMaker');
        var stopButton = document.getElementById('stop_animateMaker');
        var pause_button = document.getElementById('pauseAniamtion');
        var continue_button = document.getElementById('continueAniamtion');
        // startButton.addEventListener('click', ()=> {
        // startAnimation(0);
        // }, false);
        // continue_button.addEventListener('click', continueAnimation);
        // pause_button.addEventListener('click', pauseAnimation);
        
        var speedInput = document.getElementById('speed');
        $(".Fast").click(function(ev) {
          var speed = speedInput.value;
          speed++;
          if (speed>10) {speed=10;}
          speedInput.value=speed;
        });
        $(".SLow").click(function(ev) {
          var speed = speedInput.value;
          speed--;
          if (speed<1) {speed=1;}
          speedInput.value=speed;
        });
        
    function initMap() {
        map = createMap("gMapView");
        map.addLayer(vectorHistory);
        map.addLayer(vectorLayerGeo);
        map.addLayer(poiLayer);
        map.addLayer(vectorLayer);
        map.addOverlay(popup);
        popup.set("type","popup");
        $(popup.element).hide();
        map.on('click', function(evt) {
          var pixel = map.getEventPixel(evt.originalEvent);
          var feature = map.forEachFeatureAtPixel(pixel,
              function(feature) {
                  return feature;
              });
          if (feature) {
              if (feature.get('type') == 'marker') {
                  followMarker(feature.getId());
              }else if (feature.get('type') == 'poi') {
                 
              }
              console.log(feature.get('type')); 
          }else{
              if (createPoiEnabled == true) {
                  if (!getNameInput().trim()) {
                    WarningToastr("Vous devez entré un nom pour le POI");return;
                  }
                  var geometry=new Point(evt.coordinate);
                  var iconStyle1 = new Style({
                         image: new Icon( /** @type {olx.style.IconOptions} */ ({
                             anchor: [0.5, 46],
                             anchorXUnits: 'fraction',
                             anchorYUnits: 'pixels',
                             src: iconPoi,
                             scale:0.7
                         }))
                     });
                  idPoi++;
                  if (!iconFeature) {
                      iconFeature = new Feature({
                         id: idPoi,
                         name: 'newPoint'
                     });
                     iconFeature.setGeometry(geometry);
                  
                     iconFeature.setStyle(iconStyle1);
                     vectorSource_poi.addFeature(iconFeature);
                     /**/
                     var collection = new Collection([iconFeature]);
                     var drag = new Translate({
                         features: collection
                     });
                     map.addInteraction(drag);
                     iconFeature.on('change', function() {
                     }, iconFeature);
                     /**/
                  }else{
                      iconFeature.setGeometry(geometry);
                      iconFeature.setStyle(iconStyle1);
                  }
                 
                  /**/
              }
          }
        
        });
        }
          //     var mapPoi=createMap("mapPoi");
       
        //   pieChart("alarmesDash",pieData,pieDrilldown,"Alarme")
        getPoi();
        getGeo();
        $(".togglePoiRtModal").click(function(ev){
        $(".POIListDropdown").toggle();
        });
        $("#hideShowPoi").click(function(ev){
        var el=$(ev.currentTarget);
        el.toggleClass("ivu-checkbox-checked");
        if(el.hasClass("ivu-checkbox-checked")){poiLayer.setVisible(false);}else{poiLayer.setVisible(true);}
        });
        $(".tabCreateGeoBtn").click(function(ev){
        console.log("tabCreateGeo clicked");
        $(".tabSpeedGeo").css("visibility","hidden");
        $(".tabCreateGeo").css("visibility","visible");
        $(".createGeoTab").css("transform"," translateX(0%) translateZ(0px)");
        $(".geoCreateGeoTabs").toggleClass("ivu-tabs-tab-active ivu-tabs-tab-focused");
        });
        $(".tabSpeedGeoBtn").click(function(ev){
        console.log("tabSpeedGeo clicked");
        $(".tabCreateGeo").css("visibility","hidden");
        $(".tabSpeedGeo").css("visibility","visible");
        $(".createGeoTab").css("transform"," translateX(-100%) translateZ(0px)");
        $(".geoCreateGeoTabs").toggleClass("ivu-tabs-tab-active ivu-tabs-tab-focused");
        });
        
        //       stockChart(fuelData,"rtFuelChart");
        // chartBar("distanceChart",data,"Distance","Km");
        // chartBar("vitesseMaxChart",data,"Vitesse Max","Km/h");
        $("#pieChart").css("height",($(".UserCard").height()));
        $("#pieChart").css("width",($(".UserCard").height()));
        $(".cicleChartTotal").css("width",($(".UserCard").height()));
        $(".cicleChartTotal").show();
        
   
        function setListDatatable() {
            if($.fn.dataTable.isDataTable('#tblReOrderServerSide')){
                dataTable.destroy();
            }
            $(".radioRow").mousedown(function () {
                $(this).find('td .dtradio').prop('checked', true);
            });
            $("#loaderDevices").hide();
            var bodyHeight = $("body").height();
            bodyHeight = bodyHeight - 388;
            localStorage.clear();

            var tableID = $("#_TableID").val();
            dataTable = $("#tblReOrderServerSide").DataTable({
                "sDom": 'r<"row"<"col-sm-12"<"pull-left"l><"dt-filter-input-custom col-sm-6 col-md-offset-6"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
                "scrollX": true,
                colReorder: true,
                "searching": true,
                "autoWidth": false,
                "bDestroy": true,
                // "ordering": false,
                "order": [[0,'asc']],
                "bPaginate": true,
                "bInfo": false,
                "bStateSave": true,
                // "iDisplayLength": 25,
                "bLengthChange": false,
                "language": {
                    "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
                    "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
                    //"searchPlaceholder": "Search Fleet",
                    "url": "//cdn.datatables.net/plug-ins/1.10.7/i18n/English.json",
                    "paginate": {
                        "next": " ",
                        "previous": " "
                    }
                },
                // processing: true, // Here first time only processing Loader is enabled.
                // serverSide: true,
                // ajax: {
                //     type: "POST",
                //     url: _reportUrl,
                //     dataType: "JSON"
                // },
                // columns: _reportColumns,
                "drawCallback": function (settings) {
                    // dataTable.ajax.url(_reportUrl);

                    $(".dataTables_paginate").addClass('dataTables_paginate_custom');
                    $('.dataTables_scrollBody').css('max-height', bodyHeight);

                    // UpdateTabCountDetail(JSON.parse(settings.json.customData));

                    //remove count tab loader 
                    $("#Dashboard_VehicleDetail_Vehicle_Tab_Div").find("#DashboardVehiclePanel").removeClass('tab-count-opacity');
                    $("#Dashboard_VehicleDetail_Vehicle_Tab_Div").find(".tab-count-bounce").hide();
                    
                }
            });  
    }
        //  start Mounted func ===========================================================================
        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                setTimeout(() => {
                    $("#SessionExpire").modal("show");
                }, 500);
                return
            }
            // store.commit('increment')
            // console.log(store.state.count)
            markerPopup=document.getElementById('popup');
            contentPopup = document.getElementById('popup-content');
            document.getElementById('btnLiveTrackingZoomOut').onclick = function () {
              const view = map.getView();
              const zoom = view.getZoom();
              view.setZoom(zoom - 1);
            };
            document.getElementById('btnLiveTrackingZoomIn').onclick = function () {
              const view = map.getView();
              const zoom = view.getZoom();
              view.setZoom(zoom + 1);
            };
             popup = new Overlay({
                id:"popup",
                element: markerPopup,
                positioning: 'top-center',
                stopEvent: true,
                offset: [-36, -20],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                scale: 0.5,
                autoPan: true,
                autoPanAnimation: {
                    duration: 0
                }
              });
          
            initMap();
            Fetch.post("/",{token:token}).then((data)=>{ 
                var tempDevices={};
                data.data.devices.forEach((device,index)=>{
                    tempDevices[device.imei]=device;
                })
                devices.value=tempDevices;
                user.value=data.data;
                var userObj=data.data.user;
                userObj.name.entreprise=userObj.en;
                infoUser.value=userObj.name;
                addMarker(user.value.devices,data.date);
                // devices.value=user.value.devices;
                // selectedDevice.value=devices.value[0];
             });
            console.log("onMounted "+new Date().getSeconds());

            listCommands=$(".CmdList");
            listCommands.find(".ivu-collapse-header").click(function(ev){
                var parent=$(ev.currentTarget).parent(".ivu-collapse-item");
                var parentIsActive=parent.hasClass("ivu-collapse-item-active");
                listCommands.find(".ivu-collapse-item").removeClass("ivu-collapse-item-active");
                listCommands.find(".ivu-collapse-content").hide();
                if(!parentIsActive){
                  parent.addClass("ivu-collapse-item-active");
                    parent.find(".ivu-collapse-content").toggle();
                }
            });




            // fin onMonted ========================================================================================================================
        })

        // var devicesLength=computed(()=> {
        //   return user.value.devices? user.value.devices.length:0;} );
          function listItemToggle(el){
             $('.selected_device').val($(el).text());
              $(".listDevices").toggle(); 
            // $(".device_item").click(function() {
               
            // });
          }
        $(document).ready(function() {
          $(".PCLoading" ).hide();
            var today=new Date();
          var mois = today.getMonth()+1;var year = today.getFullYear();var startDay=today.getDate(); var endDay=today.getDate()+1;
          mois=mois<10?"0"+mois:mois; startDay=startDay<10?"0"+startDay:startDay; endDay=endDay<10?"0"+endDay:endDay;
          $("#endDateHistorique").val(endDay+"/"+mois+"/"+year+" 00:00");
          $("#startDateHistorique").val(startDay+"/"+mois+"/"+year+" 00:00");
          if($(".DevicePanel").width()>=$(".statutBarCountainer").width()){
              $('.statutBarNav').hide();
          }

          $(".expendRightInfos").find(".ivu-collapse-header").click(function(el) {
              var expendableRight=$(".expendRightInfos");
              var currentElement=$(el.currentTarget).parent(".expendRightInfos");
              var currentElIsExpended=currentElement.hasClass("ivu-collapse-item-active");
              if (expendableRight.hasClass("ivu-collapse-item-active")) {
                      $(".RealDataPanel").css("width","150px");  
                      expendableRight.find(".ivu-collapse-content").hide();
                      expendableRight.removeClass("ivu-collapse-item-active");
                      $(".CenterPanel").css("width","calc(((100vw - 150px) - 50px) - 10px)"); 
                      $(".DeviceExpendName").hide();
              }
              if (!currentElIsExpended) {
                      currentElement.addClass("ivu-collapse-item-active");
                      $(".RealDataPanel").css("width","300px");  
                      currentElement.find(".ivu-collapse-content").show();
                      $(".CenterPanel").css("width","calc(((100vw - 300px) - 50px) - 10px)")
                      $(".DeviceExpendName").show();
              }
              setTimeout(function(){ map.updateSize(); },300) 
            })

           
            $(".type_zone").click(function(element) {
              $(".zoneTypeListe").toggle();  
              if ($(".zoneTypeListe").is(":hidden")) {
                  $(".selectTypeZoneVisibility").removeClass("ivu-select-visible");
              }else{
                  $(".selectTypeZoneVisibility").addClass("ivu-select-visible");
              }
            });
              $(".IconItem").click(function () {
                    var img=$(this).find('img').attr('src');
                    $("#electedPoiIcon").attr("src",img);
                    iconPoi= img;
                      
              });
              $("#ivu-poptip-id").mouseover(function() {
                      $("#iconsContainer").show();
              }).mouseout(function() {
                  if(!$('#iconsContainer').is(":focus") && $('#AddBarContainer:hover').length == 0) {
                      $("#iconsContainer").hide();
                  }
                      
              });
              $(".historiqueToggleDetails").click(function() {
                      var len=hdata.length;
                      if(len<1){return;}
                      var value=$(this).find('input').val()=="true"?false:true;  
                      $(this).find('input').val(value);
                      if (value) {
                              $(this).addClass("ivu-switch-checked"); 
                      }else{
                              $(this).removeClass("ivu-switch-checked");      
                      }
                      if ($(this).find('input').attr("name")=="trajet") {
                            if(value){
                                toggleLines(1);return;
                            }  
                            toggleLines(0);  
                      }else if ($(this).find('input').attr("name")=="stopPosition") {
                            showHideOverlayHistory();  
                      }else if ($(this).find('input').attr("name")=="startPoint") {
                            if(value){
                                hideOverlay("overlay0",1);return;
                            }  
                              hideOverlay("overlay0",0);  
                      } else if ($(this).find('input').attr("name")=="endPoint") {
                            if(value){
                                  hideOverlay("overlayEnd",1);return;
                            }  
                              hideOverlay("overlayEnd",0);  
                      } else if ($(this).find('input').attr("name")=="longStop") {
                          if(trajetHistory>1){
                            if(value){
                                  hideOverlay("overlays",1);return;
                            }  
                              hideOverlay("overlays",0);  
                          }else{
                            WarningToastr("Vous n'avez qu'un seul stop!")
                          }
                      }   
                  
            })
            $('.datepicker').datetimepicker({
              format:'d/m/Y H:i',
              formatDate:'d/m/Y',
              closeOnDateSelect:true,
              minDate:'12/09/2021',//yesterday is minimum date(for today use 0 or -1970/01/01)
              maxDate:new Date(),//tomorrow is maximum date calendar
              onChangeDateTime(dp,$input){
                //console.log($input);
              } 
            });
           console.log("ready "+new Date().getSeconds());
           mainContent = $("#MainContent");


        // history custum start=============================================================================================
                $(".lang_change").click(function () {
                    var lang_value = $(this).attr("data-value");
                    $.post("/Base/SwitchLanguage", { applicationLanguage: lang_value }, function (data) {
                        if (data == true || data == 'True') {
                            window.location.reload();
                        } else {
                            ErrorToastr('Something went wrong.!');
                        }
                    });
                });
                $("#LiveTrackingDataCountSpan").html('0');

            $('._chkAll').change(function () {
                if ($(this).is(':checked')) {
                    $('.chkLiveTrackingVehicle').prop('checked', false);
                    GetCheckBoxValues();
                    $(this).prop('checked', true);
                }
                else {
                    GetCheckBoxValues();
                }
            });

            $(".chkLiveTrackingVehicle").change(function () {
                if ($(this).is(':checked')) {
                    //$('.chkLiveTrackingVehicle').prop('checked', false);
                    $('._chkAll').prop('checked', false);
                    $(this).prop('checked', true);
                    GetCheckBoxValues();
                }
                else {
                    GetCheckBoxValues();
                }
            });

            if ('True' == 'True') {
                $("._chkAll").prop("checked", true);
            }
            else {
                if ('00000000-0000-0000-0000-000000000000' != 'null' && '00000000-0000-0000-0000-000000000000' != '00000000-0000-0000-0000-000000000000')
                {
                    setTimeout(function () {
                        GetCheckBoxValues();
                    }, 500);
                }
                $("._chkAll").prop("checked", false);
            }
            $("#HistoryCountSpan").html('6');

            // if ('724d2ae6-8f5c-4c05-995f-5784dc76b700' != '' && '724d2ae6-8f5c-4c05-995f-5784dc76b700' != '00000000-0000-0000-0000-000000000000') {

            //     setTimeout(function () {
            //         // if vehicle id exist and tracking history type id valid, then history search button triggered.
            //         if (parseInt($("#TrackingHistoryTypeID").val()) > 0 && parseInt($("#TrackingHistoryTypeID").val()) < 5) {
            //             isLiveActive = false;
            //             isHistoryActive = true;
            //             // $("#HistoryActivityTab").trigger("click");
            //             $("#chkAnimate").prop("checked", false);

            //             //initMap();
            //             $("#btnHistorySearch").trigger("click");
            //         }
            //     }, 1000);
            // }
            var tblserMain = $("#NearByVehicleList").height();
            $('#NearByVehicleList').slimScroll({
                height: tblserMain + 'px',
                alwaysVisible: true
            });

            $("#btnMapViewLocationSearch1").click(function () {
                DrawSearchAddress1();
            });

            $("#btnMapViewLocationCancel1").click(function () {
                $("#MapViewLocationSearch1").val("");
                $("#btnMapViewLocationCancel").trigger("click");
                $("#btnMapViewPoiCancel").trigger("click");
                $("#_TrackingPOIID").select2("val", "");
                searchLatLan = null;
                $("#NearByVehicleCount").html("0");
                $("#NearByVehicleList").html("");
            });


            //$('#ToDate').bootstrapMaterialDatePicker({ weekStart: 0, format: 'MM/DD/YYYY HH:mm' });
            //$('#FromDate').bootstrapMaterialDatePicker({ weekStart: 0, format: 'MM/DD/YYYY HH:mm' }).on('change', function (e, date) {
            //    $('#ToDate').bootstrapMaterialDatePicker('setMinDate', date);
            //});

            $(".select2").select2({
                theme: "bootstrap",
                allowClear: !0,
            });

            // DateTime Picker
            $('.trackingDateTimePicker').datetimepicker({
                format: 'm/d/Y H:i',
                step: 10
            });

            $(".link-create").click(function () {
            event.preventDefault();

            var gotoLink = $(this).attr("data-link");
            window.location.href = gotoLink;
        });

        $("#close_mapviewsidebar").click(function () {
            $("#mapviewsidebar").trigger("click");
        });

        // _GetAllPoiNameLists();
        $("#close_birdeyeviewsidebar").click(function () {
            $("#birdeyeviewsidebar").trigger("click");
        });
        $(".select2").select2({
            theme: "bootstrap",
            dir: selectOptions.dir,
            allowClear: !0,
        });
        var rightScroll = $("#_OffSideBarMapViewListDiv").height();

        $('#_OffSideBarMapViewListDiv').slimScroll({
            height: rightScroll + 'px',
            alwaysVisible: true
        });



        // $('#map-poi-radius-slider').slider().change(function () {
        //     ChangeCircleRadius($('#map-poi-radius-slider').val());
        // });

        //  $('#_OffSideBarMapViewListDiv').slimScroll();

        $('#txtPoiMapGridSearch').keyup(function () {
            var obj = $(this);
            FilterMapPoiSearchList(obj);
        });

        $(".btn-act").click(function () {
            $(".btn-act").removeClass("active");
            $(this).addClass("active");
        });

        $("#close_mapviewsidebarpoi").click(function () {
            $("#mapviewsidebarpoi").trigger("click");
            cancelGeoDraw();
        });


            // GetMapPOITypes();

        var postUrl1 = $("#PostUrl1").val();

        $("#btnMapViewPoiSave").click(function () {
            saveZone();
        });
        setTimeout(function () {
            $("#vsSlimscroll").slimScroll({
                height: '650px',
                alwaysVisible: true
            });
        }, 800);

        $(".summary_vs_event").click(function () {
            $("#summary_body_id").slideToggle(120, "linear", function () { });
        });

        // $('#VehicleDetailDiv').html('<img alt="typeImg" title="VAN" src="/img/VAN.png"  height="18" width="18" class="mr-sm"/>BAB 4048210');
        // $('#SearchHistoryRangeDiv').html('<small class="f-12 fw-500 mt text-muted1">17/08 01:20 - 21/08 01:20</small>');

        var simpleSidebarAlignOption = {
            align: "right",
        }
        if ('English' == 'Arabic') {
            simpleSidebarAlignOption.align ="left"
        }
        var tempToVehicleID;
        var liveTrackingSpeedChart;
        var OffsidebarDirectionOpen, OffsidebarMapViewOpen, OffsidebarNearbyOpen, OffsidebarMapViewPoiOpen;
        var drectionOffsidebarinit = {
            selectors: {
                trigger: "#Link_directionsidebar",
                quitter: "#close_directionsidebar"
            },
            animation: {
                easing: "easeOutQuint"
            },
            align: simpleSidebarAlignOption.align,
            zIndex: 3002,
            top: 56,
            sidebar: {
                width: 320
            },
            mask: {
                display: false
            },
            events: {
                on: {
                    animation: {
                        open: OffsidebarDirectionOpen = function (locationName, lat, lng, vehicleID, poiZoneID) {
                            $("#LoadOffsidebarDirectionDiv").html(GetLoader());
                            $.get("/Gps/Tracking/OffsidebarDirection", function (data) {
                                $("#LoadOffsidebarDirectionDiv").html(data);
                                setTimeout(function () {
                                    if (poiZoneID != null && poiZoneID != 'null') {
                                        $('.fromPOI').trigger('click');
                                        tempFromPoiZoneID = poiZoneID;
                                    }
                                    $('#FromDirection_Location').val(locationName);
                                    fromlatlng = lat + ',' + lng;
                                    $('.toVehicle').trigger('click');
                                    tempToVehicleID = vehicleID;
                                }, 100);
                            });
                        },
                    }
                }
            }
        }


            localeOptions.format = 'MM/DD/YYYY HH:mm';

            OffsidebarNearbyOpen = function () {
                $("#LoadOffsidebarNearbyDiv").html(GetLoader());
                $.get("/Gps/Tracking/OffsidebarNearby", function (data) {
                    $("#LoadOffsidebarNearbyDiv").html(data);
                });
            }

            setTimeout(function () {

                $("#OffsidebarNearby").simplerSidebar({
                    selectors: {
                        trigger: "#Link_mapviewsidebarpNearby",
                        quitter: "#close_offsidebarNearBy"
                    },
                    animation: {
                        easing: "easeOutQuint"
                    },
                    top: 56,
                    sidebar: {
                        width: 320
                    },
                    align: simpleSidebarAlignOption.align,
                    mask: {
                        display: false
                    },
                    events: {
                        on: {
                            animation: {
                                open: OffsidebarNearbyOpen,
                            }
                        }
                    }
                });

            }, 100);
            setTimeout(function () {
                $("#OffsidebarDirection").simplerSidebar(drectionOffsidebarinit);
            }, 300)

            $(".select2").select2({
                theme: "bootstrap",
                dir: selectOptions.dir,
                allowClear: !0,
            });

            // $('#TrackingHistoryTypeID').val();
            $('#daterange-history').daterangepicker({
                timePicker24Hour: true,
                timePicker: true,
                //timePickerSeconds: true,
                timePickerIncrement: 1,
                opens: "left",
                drops: "up",
                locale: localeOptions,
                ranges: {
                    'Today': [moment().startOf('day'), moment().endOf('day')],
                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                },
                startDate: new Date($('#TrackingHistoryFromDate').val()),
                endDate: new Date($('#TrackingHistoryToDate').val())
            }, function (start, end) {
                $("#TrackingHistoryFromDate").val(start.format('YYYY-MM-DD HH:mm:ss'));
                $("#TrackingHistoryToDate").val(end.format('YYYY-MM-DD HH:mm')+":59");
                $('#daterange-history span').html(start.format('DD/MM/YYYY HH:mm') + ' - ' + end.format('DD/MM/YYYY HH:mm'));
            });

            $(".map-view-serach-filter li a").click(function () {

                var iconClass = $(this).find("i").attr("data-iconclass");
                $("#serachTypeicon").removeClass().addClass(iconClass);
                var inputId = $(this).attr("data-showinputId");
                $(".mapview-serach-input").removeClass("active");
                $("#" + inputId).addClass("active");

            });

            $(".LiveTrackingEvent").click(function (event) {
                event.preventDefault();
                if ($("#LiveTrackingDiv").hasClass("isDown")) {

                    $("#LiveTrackingDiv").stop().animate({ height: "48px" }, 200);
                } else {
                    $("#LiveTrackingDiv").stop().animate({ height: "280px" }, 200);
                }
                $("#LiveTrackingDiv").toggleClass("isDown");
                return false;
            });

            liveTrackingSpeedChart = Highcharts.chart('LiveTrackingSpeed', {
                chart: {
                    height: 260,
                    type: 'spline',
                    animation: Highcharts.svg, // don't animate in old IE
                    marginRight: 10,
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                    //events: {
                    //    load: function () {

                    //        // set up the updating of the chart each second
                    //        var series = this.series[0];
                    //        setInterval(function () {
                    //            var x = (new Date()).getTime(), // current time
                    //                y = Math.random();
                    //            series.addPoint([x, y], true, true);
                    //        }, 1000);
                    //    }
                    //}
                },

                time: {
                    useUTC: false
                },

                title: {
                    text: ''
                },

                accessibility: {
                    announceNewData: {
                        enabled: true,
                        minAnnounceInterval: 15000,
                        announcementFormatter: function (allSeries, newSeries, newPoint) {
                            if (newPoint) {
                                return 'New point added. Value: ' + newPoint.y;
                            }
                            return false;
                        }
                    }
                },

                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 150
                },

                yAxis: {
                    title: {
                        display: false,
                        text: ' '
                    },
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }]
                },

                tooltip: {
                    headerFormat: '', // '<b>{series.name}</b><br/>',
                    pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}'
                },

                legend: {
                    enabled: false
                },

                exporting: {
                    enabled: false
                },

                series: [{
                    name: 'Random data',
                    data: [],
                    //(function () {
                    //    // generate an array of random data
                    //    var data = [],
                    //        time = (new Date()).getTime(),
                    //        i;

                    //    for (i = -19; i <= 0; i += 1) {
                    //        data.push({
                    //            x: time + i * 1000,
                    //            y: Math.random()
                    //        });
                    //    }
                    //    return data;
                    //}())
                }]
            });

            // GetVehicleList(); GetDriverList(); GetPOIList();




        $('#MapViewVehicleSearch').change(function () {
            GetSearchCurrentLocation($(this).val(), 1); // Vehicle location search
        });
        $('#MapViewDriverSearch').change(function () {
            GetSearchCurrentLocation($(this).val(), 2); // Driver location search
        });
        $('#MapViewPOISearch').change(function () {
            GetSearchCurrentLocation($(this).val(), 3); // POI location search
        });



        var timeReportChart;
            $(".check-radio").click(function () {
                //var timelinename = $(this).parent('.c-radio').text().trim();
                ShowBottomTimelineGraph($(this).attr('id'), $('#TrackingHistoryFromDate').val(), $('#TrackingHistoryToDate').val());
            });

            $('.chk-livechart').click(function () {
                $('#LiveChartTypeID').val($(this).attr('id'));
                liveTrackingSpeedChart.redraw();
            });


        $(".summary_vs_event").click(function () {
            var id = $(this).attr("data-divID");
            $("#" + id).slideToggle("slow");

        });


        $(".timeline-event").click(function (event) {
            event.preventDefault();
            if ($("#TimelineGraphDivID").hasClass("isDown")) {
                $("#TimelineGraphDivID").stop().animate({ height: "280px" }, 200);
            } else {
                $("#TimelineGraphDivID").stop().animate({ height: "40px" }, 200);
            }
            $("#TimelineGraphDivID").toggleClass("isDown");
            return false;
        });


        $(".vs-btn-close").click(function () {
            $("#VHS-Open").css({ "display": "block" });
            $("#vehiclestatus-historyID").find(".VHS-Div").css({ "display": "none" });
            $("#vehiclestatus-historyID").animate({ width: '0px' });
            $('#TimelineGraphDivID').css({ "display": "none" });
        });

        $("#VHS-Open").click(function () {
            $(this).css({ "display": "none" });
            $("#vehiclestatus-historyID").find(".VHS-Div").css({ "display": "block" });
            $("#vehiclestatus-historyID").animate({ width: '410px' }, 100);
            $('#TimelineGraphDivID').css({ "display": "block" });
        });

        $("#Link_mapviewsidebar").click(function () {
            $("#mapviewsidebar").trigger("click");
        });

        //$("#Link_mapviewsidebarpNearby").click(function () {
        //    $("#mapviewsidebarpNearby").trigger("click");
        //});
        $("#Link_mapviewsidebarpoi").click(function () {
            isPoiModeMap = true;
            $("#mapviewsidebarpoi").trigger("click");

            GetMapPOITypes();
            GetMapPOIList();
        });

        $("#btnLiveTrackingCluster").click(function () {
            // here you need to add code to save setting in DB
            var icon = $(this).find('em:first');
            icon.toggleClass('cluster-on').toggleClass('cluster-off');
        });

        $("#btnLiveTrackingArabic").click(function () {
            // here you need to add code to save setting in DB
            var icon = $(this).find('em:first');
            icon.toggleClass('arabic-off').toggleClass('arabic-on');
        });

        //$("#Link_directionsidebar").click(function () {
        //    $("#directionsidebar").trigger("click");
        //});



        $("#btnExportReport").click(function () {
            Print_Export(false,true);
        });
        $("#btnPrintReport").click(function () {
            Print_Export(true, false);
        });




        //Default filter side will open
        $("#mapviewsidebar").trigger("click");
        setTimeout(function () {
            // initMap();
            isLiveActive = true;
            $('#txtSearch').keyup(function () {
                var obj = $(this);
                FilterVehicle(obj);
            });
        }, 1000);
        // history custum end===============================================================================================
    });
//  History FUNCS ============================================================================================================
     


var iCount = 1;

function _ChangePassword() {
    mainContent.addClass("whirl");
    $.get(url_partial_change_password, function (data) {
        mainContent.removeClass("whirl");
        //$('#changePasswordModal').appendTo("body").modal('show');
        //$("#changePasswordDiv").html(data);
        LoadSubModalCommonByID(6, "", "", data); //Note : (6 - Change Password) PopupModel Enum
    });
}
function ChangeCircleRadius(radius) {
    draw
}
// theme function, called 2 times, therefore, set function call at 2nd time instead of first time.
function UpdateUserTheme(theme) {
    if (iCount == 2) {
        iCount = 1;
        $.post(url_update_user_theme,
            { themeColor: theme }, function (data) {
                if (data) {
                    SuccessToastr('Theme successfully updated!');
                }
                else {
                    WarningToastr('Theme not updated, Please try again.');
                }
            });
    }
    else if (iCount == 1) {
        iCount = 2;
    }
}

function UpdatePopupAlert() {
    if ($("#chk-popup-alert").prop("checked")) {
        var popupalert = true;
    }
    else {
        var popupalert = false;
    }
    $.post(url_update_popup_alert_setting,
        { popupalert: popupalert }, function (data) {
            if (data) {
                SuccessToastr('Popup Alert Setting successfully updated!');
            }
            else {
                WarningToastr('Popup Alert Setting not updated, Please try again.');
            }
        });
}

   

var isLiveActive = true;
var isHistoryActive = false;
function _GetAllPoiNameLists() {
    $.get(url_get_poi_id_name_list, {
        isAll: false,
    }, function (data) {
        $("#_TrackingPOIID").empty();
        $("#_TrackingPOIID").append($("<option></option>").val("").html(""));
        $.each(data, function (key, value) {
            $("#_TrackingPOIID").append($("<option></option>").val(value.Value).html(value.Text));
        });
    });
}

function GetMapPOITypes() {
    $.get("/Setup/POIZone/GetPOITypes/", function (data) {
        $("#MapViewPOIType").append($("<option></option>").val(null).html(''));
        $.each(data, function (key, value) {
            $("#MapViewPOIType").append($("<option></option>").val(value.Value).html(value.Text));
        });
    });
}

function GetMapPOIList() {
    $("#_OffSideBarMapViewListDiv").html(GetLoader());
    $.get("/Setup/POIZone/_GetPOIListForTracking/", function (data) {
        $("#_OffSideBarMapViewListDiv").html(data);
    });
}
function activate(ele) {
    $('.bullet').removeClass("active");
    $('.VHS_running_text').removeClass("active");
    $('.table-row').removeClass('bg-blue-light');
    $(ele).addClass("active");
    $(ele).parent('p').prev('.bullet').addClass("active");
}
function activate_running(ele) {
    $('.bullet').removeClass("active");
    $('.VHS_running_text').removeClass("active");
    $('.table-row').removeClass('bg-blue-light');
    $(ele).parents('tr.table-row').addClass('bg-blue-light');
    $(ele).addClass("active");
}
function callbackToDirVehicleID()
{
    if (tempToVehicleID != null) {
        GetRoute();
    }
}
function callbackFromDirPOI() {
    if (tempFromPoiZoneID != null) {
        GetRoute();
    }
}
function DashboardVehicleMapView(vehicleID) {
    var chkVehicle = null;
    $('.chkLiveTrackingVehicle').each(function (e, t) {
        debugger
        if ($(t).attr('data-vehicle-id') == vehicleID)
            chkVehicle = $(t);
    });
    if (chkVehicle != null) {
        $('._chkAll').prop('checked', false);
        $('.chkLiveTrackingVehicle').prop('checked', false);
        chkVehicle.prop('checked', true);
        setTimeout(function () {
            GetCheckBoxValues();
        }, 100);
    }
}

// function DashboardVehicleHistoryMapView(vehicleID, historyTypeID) {
//     isLiveActive = false;
//     isHistoryActive = true;
//     $("#HistoryActivityTab").trigger("click");


//     var chkVehicle = null;
//     $('.rdoVehicle_history').each(function (e, t) {
//         if ($(t).val() == vehicleID)
//             chkVehicle = $(t);
//     });
//     if (chkVehicle != null) {
//         DeleteMarkers();
//         chkVehicle.prop('checked', true);
//         $("#chkAnimate").prop("checked", false);
//         setTimeout(function () {
//             $("#btnHistorySearch").trigger("click");
//         }, 100);
//     }
// }
function GetVehicleList() {
    $.get(url_get_vehicle_drop_down, function (data) {
        $("#MapViewVehicleSearch").empty();
        $("#MapViewVehicleSearch").append($("<option></option>").val(empty_guid).html("All"));

        $.each(data, function (key, value) {
            $("#MapViewVehicleSearch").append($("<option></option>").val(value.Value).html(value.Text));
        });
    });
}

function GetPOIList() {
    $.get("/Setup/POIZone/GetPoiZoneIdAndNameList/", { isAll: false }, function (data) {
        $("#MapViewPOISearch").append($("<option></option>").val("").html(""));
        $.each(data, function (key, value) {
            $("#MapViewPOISearch").append($("<option></option>").val(value.Value).html(value.Text));
        });
    });
}

function GetDriverList() {
    $.get("/Setup/Driver/GetDriverIdName", function (data) {
        $("#MapViewDriverSearch").append($("<option></option>").val("").html(""));
        $.each(data, function (key, value) {
            $("#MapViewDriverSearch").append($("<option></option>").val(value.Value).html(value.Text));
        });
    });
}
function GetSearchCurrentLocation(id, searchTypeID) {
    $.get('/Gps/Tracking/GetSearchCurrentLocation',
        { id: id, searchTypeID: searchTypeID },
        function (data) {
            if (data.lat != 0 && data.lng != 0) {
                var searchLatLan = new google.maps.LatLng(data.lat, data.lng);
                DrawSearchElement(searchLatLan, searchTypeID);
            } else {
                WarningToastr('Location not found..');
            }
        });
}

function HighChartLiveTrackingSpeed(data) {
    $('#LiveTrackingDiv').show();
    var yVal = data.Speed;
    if ($('#LiveChartTypeID').val() == 1) {
        yVal = data.FuelLevel;
    } else if ($('#LiveChartTypeID').val() == 9) {
        yVal = data.EngineTemp;
    } else if ($('#LiveChartTypeID').val() == 11) {
        yVal = data.TemperatureSensor1;
    } else if ($('#LiveChartTypeID').val() == 12) {
        yVal = data.TemperatureSensor2;
    } else if ($('#LiveChartTypeID').val() == 13) {
        yVal = data.TemperatureSensor3;
    } else if ($('#LiveChartTypeID').val() == 14) {
        yVal = data.TemperatureSensor4;
    }

    liveTrackingSpeedChart.series[0].addPoint({
        x: data.TimestampUnix,
        y: yVal
    });
}
function ShowBottomTimelineGraph(typeID, fromDate, toDate) {
    $.get('/Reports/Report/_GetTimeChartData',
        {
            type: typeID,
            vehicleID: $('#TrackingVehicleID').val(),
            fromDate: fromDate,
            toDate: toDate,
        }, function (data) {
            $('#TimelineGraphDivID').css({ "display": "block" });
            //$('#TimelineGraphDivID').css({ "height": "280px" });
            var vehicleName = $("input[name='rdoVehicle_history']:checked").attr('data-vname');
            var categories = [];
            var values = [];
            $.each(data.ReportChartViewModel, function (key1, value1) {

                categories.push(parseInt(value1.Timestamp));
                values.push(parseInt(value1.Value));
            });


            Highcharts.chart('container', {
                chart: {
                    type: 'spline', height: 200
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        format: '{value:%l:%M %p}',
                    },
                    plotLines: [{
                        //value: poiTime,
                        dashStyle: 'dash',
                        width: 1,
                    }]
                }, title: { text: '' },
                yAxis: {
                    title: { text: '' }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                tooltip: {
                    //split: true,
                    //valueDecimals: 0,
                    //valueSuffix: '°C'
                    formatter: function () {
                        return '<b>' + this.series.name + '</b><br/>' +
                            Highcharts.dateFormat('%A, %b %e, %H:%M %p',
                                new Date(this.x))
                            + ', ' + this.y + '';
                    },

                    split: false,
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        label: {
                            connectorAllowed: false
                        },
                    }
                },
                series: [{
                    showInLegend: false,
                    name: vehicleName,
                    data: values,
                    color: data.ColorCode
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            });
        });
}
function GetAlertDetails() {
    var vehicleID = $("input[name='rdoVehicle_history']:checked").val();
    if (vehicleID != undefined && vehicleID != "" && isHistoryActive == true) {
        $("#VehicleAlertsHistoryDiv").html(GetLoader());
        $.get("/Gps/HistorySummary/_GetVehicleAlerts", {
            vehicleID: vehicleID,
            fromDate: $('#FromDate').val(),
            toDate: $('#ToDate').val()
        }, function (data) {
            $("#VehicleAlertsHistoryDiv").html(data);
        });
    }
}

function GetVehicleRefuels() {
    var vehicleID = $("input[name='rdoVehicle_history']:checked").val();
    if (vehicleID != undefined && vehicleID != "" && isHistoryActive == true) {
        $("#VehicleRefuelHistoryDiv").html(GetLoader());
        $.get("/Gps/HistorySummary/_GetVehicleRefules", {
            vehicleID: vehicleID,
            fromDate: $('#FromDate').val(),
            toDate: $('#ToDate').val()
        }, function (data) {
            $("#VehicleRefuelHistoryDiv").html(data);
        });
    }
}
function Print_Export(isPrint, isExport) {
    var reportTypeID = 53;
    var startDate = new Date($('#TrackingHistoryFromDate').val());
    var endDate = new Date($('#TrackingHistoryToDate').val());
    var vehicleID = $("#TrackingVehicleID").val();
    var alertType = 1;

    if (reportTypeID != "" && reportTypeID != 0 && reportTypeID != null &&
        startDate != "" && startDate != null &&
        endDate != "" && endDate != null) {
        if (reportTypeID == 30) { // alert log
            if (alertType == null || alertType == "") {
                WarningToastr("Please select all fields.");
                return;
            }
        }

        var filterParameter = {
            'ReportID': reportTypeID,
            'FromDate': startDate,
            'ToDate': endDate,
            'VehicleID': vehicleID
        };

        if (isPrint) {
            window.open(url_print_report + "/?filterParameter=" + JSON.stringify(filterParameter));
        }
        else if (isExport) {
            window.open(url_export_excel_report + "/?filterParameter=" + JSON.stringify(filterParameter));
        }
    }
    else {
        WarningToastr("Please select all fields.");
    }
}
function FilterVehicle(obj) {
    var $rows;
    if ($("#LiveActivityTab").hasClass("active")) {
        $rows = $('#tblLiveTripData tr');
    }
    else if ($("#HistoryActivityTab").hasClass("active")) {
        $rows = $('#tblHistory tr');
    }

    var val = $.trim(obj.val()).replace(/ +/g, ' ').toLowerCase();

    $rows.show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
    }).hide();


    if ($("#LiveActivityTab").hasClass("active")) {
        var count = $('#tblLiveTripData tr:not([style*="display: none"])').length;
        $("#LiveTrackingDataCountSpan").html(count);
    }
    else if ($("#HistoryActivityTab").hasClass("active")) {
        var count = $('#tblHistory tr:not([style*="display: none"])').length;
        $("#HistoryCountSpan").html(count);
    }
}
function LiveActivity(obj) {
    if (!$(obj).hasClass("active")) {
         NavigateLogin();
    }
}
       
function HistoryActivity(obj) {
    if (!$(obj.target).hasClass("active")) {
        $("#LiveActivityTab").removeClass("active");
        $("#HistoryActivityTab").addClass("active");

        $("#Live").removeClass("active");
        $("#History").addClass("active");
        $("#GeoFense").removeClass("active");
        $("#GeoFenseTab").removeClass("active");
        ClearSerach();
    }
}
function ClearSerach() {
    $("#txtSearch").val('');
}
function NavigateLogin() {
    $("#HistoryActivityTab").removeClass("active");
    $("#LiveActivityTab").addClass("active");
    $("#Live").addClass("active");
    $("#History").removeClass("active");
    $("#GeoFense").removeClass("active");
    $("#GeoFenseTab").removeClass("active");
    ClearSerach();
}
// Get Vehicle List
function GetLiveTrackingVehicleList(obj, status) {
    if ($(obj).attr("data-active") == "false") {
        if (status == 0) {
            $(".btn-select-vehicle").removeClass("btn-primary");
            $("#_btnSelectAllVehicle").addClass("btn-primary");
            $("#IsAll").val(true);
        }
        else if (status == 1) {
            $(".btn-select-vehicle").removeClass("btn-primary");
            $("#_btnSelectRunningVehicle").addClass("btn-primary");
            $("#IsAll").val(true);
        }
        else if (status == 2) {
            $(".btn-select-vehicle").removeClass("btn-primary");
            $("#_btnSelectIdlingVehicle").addClass("btn-primary");
            $("#IsAll").val(true);
        }
        else if (status == 3) {
            $(".btn-select-vehicle").removeClass("btn-primary");
            $("#_btnSelectStoppedVehicle").addClass("btn-primary");
            $("#IsAll").val(true);
        }
        else if (status == 4) {
            $(".btn-select-vehicle").removeClass("btn-primary");
            $("#_btnSelectDisconnectedVehicle").addClass("btn-primary");
            $("#IsAll").val(true);
        }
        _trackingStatus = status;
        var isAll = $("#IsAll").val();
        $("#TrackingLiveVehicleListDiv").html(GetLoader());
        $(".live-tab").attr("data-active", "false");
        $(obj).attr("data-active", "true");
        $("#IsAll").val(false);
        $.ajax({
            url: "/Gps/Tracking/_GetVehicleStatus/",
            data: {
                status: status,
                isAllCheck: isAll,
            },
            type: "GET",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            async: true,
            success: function (data) {
                $("#TrackingLiveVehicleListDiv").html(data.liveVehicleList);
            },
            error: function () {
                WarningToastr("Something went wrong!!!");
            }
        });
   
    }
}
function SelectAllVehicle(obj) {
    $("#tabAllVehicle").trigger("click");
}

function SelectRunningVehicle(obj) {
    $("#tabRunningVehicle").trigger("click");
}

function SelectIdlingVehicle(obj) {
    $("#tabIdlingVehicle").trigger("click");
}

function SelectStoppedVehicle(obj) {
    $("#tabStoppedVehicle").trigger("click");
}

function SelectDisconnectedVehicle(obj) {
    $("#tabDisconnectedVehicle").trigger("click");
}
function manageGeoModal(id) {
    mainContent.addClass("whirl");
    Fetch.post("/getGeoById",{token:token,id:id}).then((data)=>{
        currentLimitVitesse.value=data.speed;
        selectedIdGeo=id;
        currentZoneName.value=data.name;
        var imeis=Object.keys({...devices.value});
        imeis.forEach((imei)=>{
            if (!data.imeis) {
                data.imeis={};
            }
            if (data.imeis[imei]) {
                devices.value[imei].speed=data.imeis[imei].speed;
                devices.value[imei].active=data.imeis[imei].active;
                devices.value[imei].enter=data.imeis[imei].alarme==1||data.imeis[imei].alarme==3?1:0;
                devices.value[imei].leave=data.imeis[imei].alarme==2||data.imeis[imei].alarme==3?1:0;
            }else{
                devices.value[imei].speed=currentLimitVitesse.value;
                devices.value[imei].active=0;
                devices.value[imei].enter=0;
                devices.value[imei].leave=0;
            }
            
        })
        mainContent.removeClass("whirl");
        $("#CommonImportSummaryModal").modal("show");
    })
    
}
</script>  
<template>
<div class="history-container">
<Header :infoUser="infoUser" :page="page"></Header>

<aside class="offsidebar1 section_mapviewsidebar">
    <nav>
        <div role="tabpanel">
            <div class="tab-content header">
                Filter
                <span class="pull-right closeaside" id="close_mapviewsidebar"><em class="icon-close"></em></span>
            </div>
            <div class="div_mapviewsidebar">
                <div class="p-search">
                    <div class="input-group group-material">
                        <input type="text" id="txtSearch" placeholder="Search" class="form-control control-material searchData">
                        <span class="input-group-addon">
                            <em class="icon-magnifier"></em>
                        </span>
                    </div>
                </div>
                <div role="tabpanel" class="outer-tab">
                    <!-- Nav tabs-->
                    <ul role="tablist" class="nav nav-tabs on-map nav_tabs_custom1">
                            <li role="presentation" id="LiveActivityTab" class="active text-center w-50per pull-left" onclick="LiveActivity(this)">
                                <a href="javascript:;" aria-controls="home" role="tab" data-toggle="tab">Live</a>
                            </li>
                                                    <li role="presentation" id="HistoryActivityTab" class="w-50per text-center pull-left" onclick="HistoryActivity(this)">
                                <a href="javascript:;" aria-controls="history" role="tab" data-toggle="tab">History</a>
                            </li>
                    </ul>
                    <!-- Tab panes-->
                    <div class="tab-content">
                        <div id="Live" role="tabpanel" class="tab-pane active">
                            <div role="tabpanel" class="inner-tab">
                                <!-- Nav tabs-->
                                <ul role="tablist" class="nav nav-tabs nav-tab-inner-widget nav-tab-5">
                                    <li role="presentation" class="live-li-first active"><a href="#tb1" data-active="true" class="live-tab" id="tabAllVehicle" onclick="GetLiveTrackingVehicleList(this,  '0')" aria-controls="tab1" role="tab" data-toggle="tab" title="All"><i class="icon-grid"></i></a> </li>
                                    <li role="presentation" class="live-li"><a href="#tb1" data-active="false" class="live-tab" id="tabRunningVehicle" onclick="GetLiveTrackingVehicleList(this, '1')" aria-controls="tab2" role="tab" data-toggle="tab" title="Moving"><i class="icon-shuffle"></i></a></li>
                                    <li role="presentation" class="live-li"><a href="#tb1" data-active="false" class="live-tab" id="tabIdlingVehicle" onclick="GetLiveTrackingVehicleList(this, '2')" aria-controls="tab3" role="tab" data-toggle="tab" title="Idle"><i class="icon-energy"></i></a></li>
                                    <li role="presentation" class="live-li"><a href="#tb1" data-active="false" class="live-tab" id="tabStoppedVehicle" onclick="GetLiveTrackingVehicleList(this, '3')" aria-controls="tab4" role="tab" data-toggle="tab" title="Stopped"><i class="icon-power"></i></a></li>
                                    <li role="presentation" class="live-li"><a href="#tb1" data-active="false" class="live-tab" id="tabDisconnectedVehicle" onclick="GetLiveTrackingVehicleList(this, '4')" aria-controls="tab5" role="tab" data-toggle="tab" title="Delayed data"><i class="icon-ban"></i></a></li>
                                </ul>
                                <!-- Tab panes-->
                                <div class="tab-content p0">
                                    <div id="tb1" role="tabpanel" class="tab-pane active">
                                        <div class="scrollable1">
                                            <table class="table table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td><em class="fa fa-angle-double-right text-green">&nbsp;</em>All</td>
                                                        <td class="text-right">
                                                            <label class="checkbox-inline c-checkbox c-checkbox1">
                                                                <input type="checkbox" value="00000000-0000-0000-0000-000000000000" data-vehicle-id="00000000-0000-0000-0000-000000000000" class="chkLiveTrackingVehicle _chkAll">
                                                                <span class="fa fa-check"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div id="TrackingLiveVehicleListDiv">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer_mapviewsidebar">
                                <div class="footer-live">
                                    <span class="pull-left">
                                        <label class="checkbox-inline c-checkbox">
                                            <input type="checkbox" checked="" value="0" id="chkLiveAutoRefresh">
                                            <span class="fa fa-check"></span> Auto Refresh
                                        </label>
                                    </span>
                                    <span id="LiveTrackingDataCountSpan">0</span>&nbsp;&nbsp;<small class="text-muted">Asset(s)</small>
                                </div>
                            </div>
                        </div>
                        <div id="History" role="tabpanel" class="tab-pane">
                            <div class="tab-list">
                                <div class="scrollable2">
                                    <div id="TrackingHistoryVehicleListDiv">
                                    </div>
                                </div>
                            </div>
                            <div class="footer_mapviewsidebar">
                                <div class="footer-history mb-sm">
                                    <span class="pull-left">Total  </span> <span id="HistoryCountSpan">0</span>&nbsp;&nbsp;<small class="text-muted">Asset(s)</small>
                                </div>
                                <div id="history-tab" class="history-tab">
                                    <div role="tabpanel">
                                        <div id="tab_Custom" role="tabpanel" class="tab-pane active">
                                            <div>
                                                <ul class="list-unstyled mb-0">
                                                    <li class="mb-sm">
                                                        <div class="btn-group">
                                                            <button type="button" class="btn-day btn btn-sm btn-default"><i class="icon-calendar"></i></button>
                                                            <div class="btn-group">
                                                                <button type="button" data-toggle="dropdown" id="btnHistoryHour" class="btn-day btn btn-sm dropdown-toggle btn-default"><span id="HistoryHourSpan">1 H</span>&nbsp;<span class="caret"></span></button>
                                                                <ul role="menu" class="dropdown-menu pull-right mw-65px">
                                                                    <li><a href="javascript:;" onclick="ChangeAutoHour('1Hr', '1 H')">1 H</a></li>
                                                                    <li><a href="javascript:;" onclick="ChangeAutoHour('2Hr', '2 H')">2 H</a></li>
                                                                    <li><a href="javascript:;" onclick="ChangeAutoHour('4Hr', '4 H')">4 H</a></li>
                                                                    <li><a href="javascript:;" onclick="ChangeAutoHour('8Hr', '8 H')">8 H</a></li>
                                                                </ul>
                                                            </div>
                                                            <button type="button" class="btn-day btn btn-sm btn-default btn-background-cyan" onclick="ChangeAutoDate('1D', this)">1D</button>
                                                            <button type="button" class="btn-day btn btn-sm btn-default" onclick="ChangeAutoDate('2D', this)">2D</button>
                                                            <button type="button" class="btn-day btn btn-sm btn-default" onclick="ChangeAutoDate('4D', this)">4D</button>
                                                            <button type="button" class="btn-day btn btn-sm btn-default" onclick="ChangeAutoDate('1W', this)">1W</button>
                                                        </div>
                                                    </li>
                                                    <li class="mb-sm">
                                                        <button type="button" class="btn p-sm btn-default" id="daterange-history">
                                                            <span>08/19/2022 00:00:00 - 08/19/2022 23:59:59</span>
                                                            <i class="fa fa-caret-down"></i>
                                                        </button>
                                                    </li>
                                                    <li class="overflow-hidden mb-sm">
                                                        <div class="row">
                                                            <div class="col-xs-12 pl-0 pr-0">
                                                                <label class="checkbox-inline c-checkbox">
                                                                    <input type="checkbox" id="chkAnimate" checked="">
                                                                    <span class="fa fa-check"></span>&nbsp;Animate
                                                                </label>
                                                                <div class="btn-group pull-right">
                                                                    <button type="button" class="btn btn-default btn-xs btn-custom-speed btn-background-cyan" onclick="CustomChangeSpeed('1x', this)">1x</button>
                                                                    <button type="button" class="btn btn-default btn-xs btn-custom-speed" onclick="CustomChangeSpeed('2x', this)">2x</button>
                                                                    <button type="button" class="btn btn-default btn-xs btn-custom-speed" onclick="CustomChangeSpeed('3x', this)">3x</button>
                                                                    <button type="button" class="btn btn-default btn-xs btn-custom-speed" id="btn4xSpeed" onclick="CustomChangeSpeed('4x', this)">4x</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="mb-sm">
                                                        <div class="overflow-hidden">
                                                            <div class="w-50per pull-left">
                                                                <button type="button" id="btnHistorySearch" class="btn btn-inverse btn-block radious-0 btn-xs btn-fa">Run History</button>
                                                            </div>
                                                            <div class="w-50per pull-left">
                                                                <div class="pull-right">
                                                                    <button type="button" id="btnPlay" class="btn btn-success btn-xs radious-0"><em class="icon-control-play"></em>&nbsp; Play</button>
                                                                    <button type="button" id="btnPause" class="btn btn-danger btn-xs radious-0"><em class="icon-control-pause"></em>&nbsp;Pause</button>
                                                                    <div class="100per" id="AutoCorrectDiv">
                                                                        <label class="checkbox-inline c-checkbox" title="Auto corrects the GPS data by removing erroneous records.">
                                                                            <input type="checkbox" id="chkAutoCorrect" checked="">
                                                                            <span class="fa fa-check"></span>&nbsp;Auto Correct
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </nav>
</aside> 
<aside class="offsidebarBirdEye section_mapviewsidebar">
    <nav>
        <div role="tabpanel">
            <div class="tab-content header">
                Filter
                <span class="pull-right closeaside" id="close_birdeyeviewsidebar"><em class="icon-close"></em></span>
            </div>
            <div class="div_mapviewsidebar">
                <div class="p-search">
                    <div class="input-group group-material">
                        <input type="text" id="txtSearchBirdEye" placeholder="Search" class="form-control control-material searchData">
                        <span class="input-group-addon">
                            <em class="icon-magnifier"></em>
                        </span>
                    </div>
                </div>
                <div role="tabpanel" class="outer-tab">
                    <div class="tab-content">
                        <div id="Live" role="tabpanel" class="tab-pane active">
                            <div role="tabpanel" class="inner-tab">
                                <!-- Nav tabs-->
                                <ul role="tablist" class="nav nav-tabs on-inner nav-tab-inner-widget nav-tab-4">
                                    <li role="presentation" class="active"><a href="#tb1" data-active="true" class="eye-bird-tab" onclick="_GetEyeBirdTrackingVehicleList(this,  '0')" aria-controls="tab1" role="tab" data-toggle="tab" title="All"><i class="icon-grid"></i></a> </li>
                                    <li role="presentation"><a href="#tb1" data-active="false" class="eye-bird-tab" onclick="_GetEyeBirdTrackingVehicleList(this,  '1')" aria-controls="tab3" role="tab" data-toggle="tab" title="Moving"><i class="icon-shuffle"></i></a></li>
                                    <li role="presentation"><a href="#tb1" data-active="false" class="eye-bird-tab" onclick="_GetEyeBirdTrackingVehicleList(this,  '3')" aria-controls="tab4" role="tab" data-toggle="tab" title="Stopped"><i class="icon-power"></i></a></li>
                                    <li role="presentation"><a href="#tb1" data-active="false" class="eye-bird-tab" onclick="_GetEyeBirdTrackingVehicleList(this,  '4')" aria-controls="tab5" role="tab" data-toggle="tab" title="Delayed data"><i class="icon-ban"></i></a></li>
                                </ul>
                                <!-- Tab panes-->
                                <div class="tab-content">
                                    <div id="tb1" role="tabpanel" class="tab-pane active">
                                        <div class="scrollable1">
                                            <div id="BirdEyeVehicleListDiv">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="footer_mapviewsidebar">
                                <div>
                                    <button type="button" id="btnBirdEyeViewSearch" class="btn btn-inverse btn-block btn-fa radious-0 mt-sm"><i class="icon-graph em-icon"></i>Follow</button>
                                </div>
                                <div class="footer-live">
                                    <span class="pull-left">
                                        Total
                                    </span>
                                    | <span id="BirdTrackingDataCountSpan">0</span>  <span class="text-muted">Asset(s)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</aside>
<input id="AddEditStatus1" name="AddEditStatus1" type="hidden" value="Add">
<input id="PostUrl1" name="PostUrl1" type="hidden" value="/Setup/POIZone/POIMapInsert">

<aside class="offsidebarMapViewPoi section_mapviewsidebar" style="width:370px">
    <nav>
        <div role="tabpanel">
            <div class="tab-content header">
                Manage POI
                <span class="pull-right closeaside" id="close_mapviewsidebarpoi"><em class="icon-close"></em></span>
            </div>
            <div class="div_mapviewsidebar">
                <!--start-->
                <div id="OffSideBarMapViewPoi_Create">
                    <input id="TrackingMapPoiID" name="TrackingMapPoiID" type="hidden" value="00000000-0000-0000-0000-000000000000">
                    <div class="p bb">
                        <i class="icon-pencil em-icon"></i> POI ZONE
                        <span class="pull-right cursor-pointer"><em class="icon-close"></em></span>
                    </div>
                    <div class="p">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="btn-group">
                                        <button type="button" id="btnMapViewPoi_Polygon" class="btn-act btnMapButton btn btn-default btn-sm active" title="Draw Rectangle"><em class="icon-graph fa-fw"></em></button>
                                        <button type="button" id="btnMapViewPoi_Circle" class="btn-act btnMapButton btn btn-default btn-sm" title="Draw Circle"><em class="fa fa-circle-thin fa-fw"></em></button>
                                    <button type="button" id="btnMapViewPoi_Rectangle" class="btn-act btnMapButton btn btn-default btn-sm" title="Draw Rectangle"><em class="fa fa-square-o fa-fw"></em></button>
                                    <!-- <button type="button" id="btnMapViewPoi_Clear" class="btn-act btnMapButton btn btn-default btn-sm" title="Clear"><em class="fa fa-eraser fa-fw"></em></button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label-control required mb0">Nom de la zone</label>
                                    <input type="text" id="createdGeoName" name="createdGeoName" placeholder="Enter name" class="form-control control-material searchData">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label-control mb0">Description</label>
                                    <input type="text" id="createdGeoDesc" name="createdGeoDesc" placeholder="Description" class="form-control control-material searchData">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label-control mb0">Limite de vitesse</label>
                                    <input type="number" id="createdGeoLimitVitesse" placeholder="Entrer la vitesse maximale autorisée" class="form-control control-material MapViewLocationLatitudeLongitude">
                                </div>
                            </div>
                            <!-- <div class="col-md-12">
                                <div class="form-group select2-material">
                                    <label class="label-control mb0">Type</label>
                                    <select class="form-control  select2 select2-hidden-accessible" id="MapViewPOIType" name="MapViewPOIType" tabindex="-1" aria-hidden="true"><option value=""></option><option value="0">None</option><option value="1">Office</option><option value="2">Customer</option><option value="3">Warehouse</option><option value="4">General</option><option value="5">School</option><option value="6">Stoppage</option><option value="7">Project Site</option><option value="8">Accommodation</option><option value="9">Construction Area</option><option value="10">Temporary Area</option><option value="11">Parking Area</option><option value="12">Gas Station</option><option value="13">Market</option><option value="14">Supermarket</option><option value="15">Store</option><option value="16">Dealer</option><option value="17">Restaurant</option><option value="18">Center Of Distribution</option><option value="19">Maintenance Workshop</option><option value="20">Toll</option><option value="21">Factory</option><option value="22">Service Station</option><option value="23">Medical Center</option><option value="24">Pharmacy</option><option value="25">Home</option><option value="26">Farm</option><option value="27">Restricated Zone</option></select><span class="select2 select2-container select2-container--bootstrap" dir="ltr" style="width: 100px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-MapViewPOIType-container"><span class="select2-selection__rendered" id="select2-MapViewPOIType-container" title=""><span class="select2-selection__clear">×</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-12">
                                <div class="form-group">
                                    <label class="label-control mb0">Radius</label>
                                  <input id="map-poi-radius-slider" data-ui-slider="" type="text" value="1" data-slider-min="1" data-slider-max="500" data-slider-step="1" data-slider-value="1" data-slider-orientation="horizontal" class="slider slider-horizontal mt pull-right" style="width: 100%; display: none;" data-value="1">
                                </div>
                            </div> -->
                            <div class="col-md-12">
                                    <button type="button" id="btnMapViewPoiSave" class="btn btn-primary btn-md btn-square">Save</button>
                                <button type="button" class="btn btn-default btn-md btn-square">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="OffSideBarMapViewPoi_List" class="d-none">
                    <div class="p bb">
                        <i class="icon-map em-icon"></i> EXISTING POI
                        <span class="pull-right" id=""></span>
                    </div>
                    <div class="p bb bg-white">
                        <div class="row">
                            <div class="col-md-12 pl">
                                <div class="input-group group-material">
                                    <input type="text" id="txtPoiMapGridSearch" name="txtPoiMapGridSearch" placeholder="Search" class="form-control control-material bg-transparent">
                                    <span class="input-group-addon">
                                        <em class="icon-magnifier"></em>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 264px;"><div id="_OffSideBarMapViewListDiv" style="margin-top: -1px; overflow: hidden; width: auto; height: 264px;">
                    </div><div class="slimScrollBar" style="background: rgb(0, 0, 0); width: 7px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px;"></div><div class="slimScrollRail" style="width: 7px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div></div>
                </div>

                <!--end-->

            </div>
        </div>
    </nav>
</aside>
        <input id="IsFleetManagementApp" name="IsFleetManagementApp" type="hidden" value="False">
        <input id="CurrentLanguage" name="CurrentLanguage" type="hidden" value="English">
        <input id="AarabicLanguage" name="AarabicLanguage" type="hidden" value="Arabic">

            <div id="_ServMaintSidebarMenuDiv"></div>
            <section id="MainContent" class="main-section-pt track-minisidebar traditional">

<input id="AddEditStatus" name="AddEditStatus" type="hidden" value="Add">
<input id="TerritorialPostUrl" name="TerritorialPostUrl" type="hidden" value="/Setup/TerritorialFence/Insert">
<div class="normalheader small-header">
    <div class="hpanel">
        <div class="panel-body">
            <h2 class="with-button">
                <em class="icon-map header-icon"></em><span class="master_title">Geo/Territory Fence</span>
            </h2>
            <div class="pull-right">
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-primary btn-square" aria-expanded="false">
                        <em class="zmdi zmdi-more-vert zmdi-hc-lg"></em>
                    </button>
                    <ul role="menu" class="dropdown-menu dropdown-menu-more pull-right">
                        <li>
                            <!-- <a href="javascript:;" id="btnImportExportTerritorial"><em class="zmdi zmdi-format-valign-top"></em>Import xls</a> -->
                            <a href="javascript:;" @click="createGeoZone()"  id="btnImportExportTerritorial"><em class="zmdi zmdi-format-valign-top"></em>Créer une Zone Géofence</a>
                        </li>
                    </ul>
                </div>
                &nbsp;
                <div class="pull-right">
                    <a href="#/Home/Setup" class="btn btn-default btn-md btn-square"><em class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg em-icon"></em>Back</a>
                </div>
            </div>
        </div>
    </div>
    <div class="hpanel animated fadeIn" style="display:none" id="TerritorialFenceDownloadAndImportDiv">
        <div class="panel-body b-t-n">
            <div class="row">
                <div class="col-md-8">
                    <a class="btn btn-primary btn-square" onclick="download_DemoFile()" href="javascript:;" id="btnDemoFile" title="Click to Download Sample File">
                        <i class="zmdi zmdi-format-valign-bottom em-icon"></i>Sample File
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span class="btn btn-default btn-file">
                                Browse…
                                <input type="file" name="TerritorialExcelSheetUpload" id="TerritorialFlUploadcsv" accept=".xlsx" class="form-control">
                            </span>
                        </span>
                        <input type="text" class="form-control" readonly="readonly">

                        <span class="input-group-btn">
                            <button class="btn btn-primary btn-flat" id="btnUploadTerritorial" data-modaltitle="Territorial Import Summary" data-modalwidth="w-60per" title="ImportExcelSheet" onclick="btnImportTerritorial_Click(this)"><i class="zmdi zmdi-format-valign-top"></i></button>
                        </span>
                        <a class="btn btn-default btn-square pull-right" href="javascript:;" id="Close_TerritorialDownloadAndImportDiv" title="Close">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="hpanel">
        <div class="panel-body b-n" style="padding:0;">
            <div class="POIZone-panel-body">
                <div class="position-relative" id="rightDivHeight">
                    <div id="gMapView" class="poiZoneMapViewSize traditional" style="position: relative; overflow: hidden;"><div style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; background-color: rgb(229, 227, 223);">
                        
                        <div class="gm-style" style="position: absolute; z-index: 0; left: 0px; top: 0px; height: 100%; width: 100%; padding: 0px; border-width: 0px; margin: 0px;">
                            
                          
                            
                        </div></div></div>
                    <div class="position-absolute mapOptionsDashboard map-panel-control">
                        <div class="btn-group-vertical">
                            <button type="button" id="btnLiveTrackingZoomIn" title="Zoom In" class="btn btn-default btn-sm radious-0"><em class="icon-magnifier-add"></em></button>
                            <button type="button" id="btnLiveTrackingZoomOut" title="Zoom Out" class="btn btn-default btn-sm radious-0"><em class="icon-magnifier-remove"></em></button>
                        </div>
                    </div>
                    <div class="filter-map-option">
                        <div class="pull-left">
                            <button class="btn btn-default btn-sm radious-0 filter-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                <i class="icon-equalizer"></i>&nbsp;<span class="ml-sm">Options</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right filter-dropdown-ul">
                                <li class="bb">
                                    <div class="pt pb checkbox c-checkbox">
                                        <label class="d-block">
                                            <input type="checkbox" value="true" id="chkShowPoiZone" name="">
                                            <span class="fa fa-check"></span>&nbsp;Enable POI(s)
                                        </label>
                                    </div>
                                    <div class="pt pb checkbox c-checkbox">
                                        <label class="d-block">
                                            <input type="checkbox" value="true" id="chkShowInfobox" name="">
                                            <span class="fa fa-check"></span>&nbsp;Show Infobox
                                        </label>
                                    </div>
                                    <div class="pt pb checkbox c-checkbox">
                                        <label class="d-block">
                                            <input type="checkbox" value="true" id="chkShowPOIName" name="">
                                            <span class="fa fa-check"></span>&nbsp;Show POI Name
                                        </label>
                                    </div>
                                    <div class="pt pb checkbox c-checkbox">
                                        <label class="d-block">
                                            <input type="checkbox" checked="" value="true" id="chkPOICluster" name="">
                                            <span class="fa fa-check"></span>&nbsp;Enable Cluster
                                        </label>
                                    </div>
                                    <div class="checkbox c-checkbox">
                                        <label class="d-block cursor-no-drop">
                                            <input type="checkbox" checked="" value="true" id="chkPOIDrawLayer" name="">
                                            <span class="fa fa-check"></span>&nbsp;Enable Fence
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="pull-left">
                            <button type="button" id="poiCollaspeExpand" title="Expand" class="btn btn-default btn-sm radious-0"><em class="fa fa-expand"></em></button>
                        </div>
                    </div>
                    <div class="customtextbox_poizonemap">
                        <table style="width:500px;">
                            <tbody><tr>
                                <td>
                                    <div class="input-group">
                                        <input type="text" id="txtPoiMapSearch" name="txtPoiMapSearch" class="form-control pac-target-input" placeholder="Search address location" autocomplete="off">
                                        <span class="input-group-addon" onclick="DrawSearchAddress()" style="cursor:pointer;">
                                            <em class="icon-magnifier"></em>
                                        </span>
                                        <div class="input-group-btn">
                                            <button type="button" data-toggle="dropdown" class="btn btn-default dropdown-toggle" style="background-color:#f5f5f5;"><em class="icon-location-pin"></em></button>
                                            <div class="dropdown-menu dropdown-filter pull-right">
                                                <form>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <input type="text" id="PoiLocationLatitudeLongitude" name="MapViewLocationLatitudeLongitude" placeholder="Latitude, Longitude" class="form-control control-material PoiLocationLatitudeLongitude">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <button type="button" id="btnPoiLocationSearch" class="btn btn-primary2 btn-sm btn-square mt f-13">Search</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                        </tbody></table>
                    </div>
                </div>
                <div class="display-block" id="leftDivList">
                    <div id="CreatePOIDiv" style="display: none;">
                        <div class="p bb">
                            <i class="icon-pencil em-icon"></i> <span class="master_title"> Geo/Territory Fence</span>
                            <span class="pull-right cursor-pointer" id="btnPoiClose"><em class="icon-close"></em></span>
                        </div>
                        <div class="p">
                            <form method="post" action="#/Setup/TerritorialFence/Insert" name="frmPOIZone" role="form" id="frmPOIZone" novalidate="novalidate">
                                <input type="hidden" id="POIZoneID" value="00000000-0000-0000-0000-000000000000">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label class="label-control required" for="PoiName" aria-required="true">Name</label>
                                            <input type="text" id="PoiName" name="PoiName" class="form-control control-material bg-transparent" placeholder="Enter" field="">
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label class="label-control required" for="PoiDescription" aria-required="true">Description</label>
                                            <input type="text" id="PoiDescription" name="PoiDescription" class="form-control control-material bg-transparent" placeholder="Enter" description="" field="">
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label class="label-control">Color</label>
                                            <div class="input-group group-material colorpicker-component pick-colorpicker colorpicker-element">
                                                <input class="form-control control-material bg-transparent" id="Color" name="Color" type="text" value="">
                                                <span class="input-group-addon">
                                                    <i id="colorpickerDiv" style="background-color: rgb(51, 204, 255);"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group select2-material">
                                            <label class="label-control" for="TerritorialFenceType">Territorial Fence Type</label>
                                            <select class="form-control control-material bg-transparent select2 select2-hidden-accessible" id="TerritorialFenceType" name="TerritorialFenceType" tabindex="-1" aria-hidden="true"><option value="0">None</option>
<option value="1">General</option>
<option value="2">Restricated Zone</option>
</select><span class="select2 select2-container select2-container--bootstrap" dir="ltr" style="width: 100px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-TerritorialFenceType-container"><span class="select2-selection__rendered" id="select2-TerritorialFenceType-container" title="None"><span class="select2-selection__clear">×</span>None</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-12 col-sm-12">
                                        <div class="panel-body b-color p-lg mb-lg">
                                            <p class="fw-500 mb0 text-muted1">Area</p>
                                            <table class="table mb0">
                                                <tbody><tr>
                                                    <td class="br bt0 w-50per" style="display:none"> <span class="fw-500 f-16" id="SqMeterSpan">0 m<sup>2</sup></span></td>
                                                    <td class="bt0 w-50per"> <span class="fw-500 f-16" id="SqKmSpan">0 km<sup>2</sup></span></td>
                                                </tr>
                                            </tbody></table>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <button type="button" id="btnPoiClear" class="btn btn-default btn-lg btn-square">Clear</button>
                                            <button type="button" id="btnTeamAllocation" class="btn btn-info btn-lg btn-square pull-right" style="display: none;">Team</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="DisplayPOIDiv" style="display: block;">
                        <div class="pl-lg pr-lg pt pb bb">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="w-95per text-overflow" title="Geo/Territory Fence">
                                        <span class="master_title">Geo/Territory Fence</span>
                                        <span class="pull-right">(<span id="TotalPoiCount">{{listGeo.length}}</span>)</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="bb bg-white pb pl-lg pr-lg" id="SearchFilterDiv">
                            <div class="row">
                                <div id="searchTextBox" class="col-md-12">
                                    <div class="input-group group-material">
                                        <input type="text" id="txtPoiGridSearch" name="txtPoiGridSearch" placeholder="Search" class="form-control control-material bg-transparent">
                                        <span class="input-group-addon">
                                            <em class="icon-magnifier"></em>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-poi position-relative" id="section-poizone">
                            <div id="POIZoneListDiv">
                                <table id="tblReOrderServerSide" class="table" role="grid">
                                    <tbody>
                                        
                                        <tr v-for="(geo, index) in listGeo" :key="index" :id="'geo'+geo._id" @click="zoomSelectedGeo(geo._id)" style="cursor:pointer;"><td class="text-center"><img src="/images/geofence_icon.png" width="18" alt="icon" title="Territory"></td><td><div class="display-flex territorial-click"><div class="w-90per pull-left"><div class="poi-name mw-250 text-overflow text-black pl-sm" onclick="ShowTerritorialFenceDetail('0527802f-0744-4830-b178-6d5c7be579ba')">{{geo.name}} &nbsp;&nbsp;  &nbsp;&nbsp; <span style="color:gray;font-size: 10px;"></span></div></div> <div class="w-10per pull-left"><div class="btn-group text-right">    <div data-toggle="dropdown" class="dropdown-toggle" aria-expanded="false">                   <em class="icon-options-vertical"></em>     </div><ul role="menu" class="dropdown-menu pull-right"><li>    <a href="javascript:;" @click="manageGeoModal(geo._id)"><em class="icon-layers f-14"></em>Gérer</a></li>
                                            <li>    <a href="javascript:;" @click="editGeoZone(geo._id,geo.name,geo.speed,geo.description)"><em class="icon-note f-14"></em>Modifier</a></li>
                                            <li>    <a href="javascript:;" @click="removeGeo(geo._id)"><em class="icon-trash f-14"></em>Supprimer</a></li>
                                        </ul></div></div></div></td></tr>
                                    </tbody>
                                </table>



</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<div id="CommonImportSummaryModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog w-98per">
        <div class="modal-content modal-content-custom">
            <div class="modal-header">
                <button type="button" onclick="CloseCommonImportSummaryModal()" class="close">×</button>
                <h4 class="modal-title">Gesttion de la zone : {{currentZoneName}}</h4>
            </div>
            <div class="modal-body">
                <div>
                    <table id="devices_table" class="display table">
                        <thead>
                            <tr>
                                <th>Imei</th>
                                <th>Conducteur</th>
                                <th>immat</th>
                                <th>Association</th>
                                <th>Alarme Entré</th>
                                <th>Alarme Sortie</th>
                                <th>Limite Vitesse</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(device,index) in devices" :key="index">
                                <td>{{index}}</td>
                                <td>{{device.driver.prenom}} {{device.driver.nom}}</td>
                                <td>{{device.immat}}</td>
                                <td>
                                    <div class="pull-right">
                                        <label class="switch">
                                            <input  type="checkbox" v-model="devices[index].active" :checked="devices[index].active">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="pull-right">
                                        <label class="switch">
                                            <input  type="checkbox" v-model="devices[index].enter" :checked="devices[index].enter">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="pull-right">
                                        <label class="switch">
                                            <input  type="checkbox" v-model="devices[index].leave" :checked="devices[index].leave">
                                            <span></span>
                                        </label>
                                    </div>
                                </td>
                                <td><input type="text" v-model="devices[index].speed" class="form-control form-control-sm"> </td>
                                <td>
                                    <button @click="assocGeoDevice(index)" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-pencil" style="color: rgb(255, 255, 255);"></i> Valider
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button id="btnDownload" onclick="DownloadFile()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal d-none"><i class="icon-cloud-download">&nbsp;</i>Download Sample File</button>
                        <span class="text-danger" id="MessageSpan"></span>
                    </div>
                    <div class="col-md-4">
                        <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                            <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary  d-none">Submit</button>
                        <button onclick="CloseCommonImportSummaryModal()" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</template>    
<style>
      .poiZoneMapViewSize {
        position: relative;
        width: 100%;
        height: calc(100vh - 148px);
    }

    .control-gray-ddl option {
        color: #333;
    }

    .display-block {
        display: block;
    }

    .display-none {
        display: none;
    }

    .collapsedBtnPOI {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .btn_switch_grp .btn_switch {
        border-radius: 50px;
        border: 1px solid #2196F3;
    }

        .btn_switch_grp .btn_switch.active,
        .btn_switch_grp .btn_switch:hover {
            background-color: #2196F3;
            color: #fff;
        }

    .map-panel-control {
        right: 10px;
        bottom: 63px;
    }

    .mapOptionsDashboard .btn-default {
        width: 41px;
        height: 40px;
    }

    .isFullScreen #rightDivHeight {
        width: 100% !important;
        float: left;
    }

    .isFullScreen #leftDivList {
        width: 0px !important;
        float: left;
        display: none !important;
    }

    .POIZone-panel-body #rightDivHeight {
        width: calc(100% - 370px);
        float: left;
    }


    .POIZone-panel-body #leftDivList {
        width: 370px;
        float: left;
    }
    #NearByVehicleList {
        min-height: calc(100vh - 418px);
        height: calc(100vh - 418px);
        overflow-y: hidden;
        margin-right: 10px;
    }

    .daterangepicker.dropup {
        top: calc(100vh - 435px) !important;
    }

    .scrollable2 {
        height: calc(100vh - 385px) !important;
        overflow: auto;
    }

    .footer_mapviewsidebar {
        bottom: 0px !important;
    }

    .nav-tab-inner-widget li {
        width: calc(100% / 5);
    }

    .nav-tab-5 li {
        width: calc(100% / 5);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }
        .nav-tab-4 li {
        width: calc(100% / 4);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }
        #_OffSideBarMapViewListDiv {
        min-height: calc(100vh - 248px);
        height: calc(100vh - 248px);
        overflow-y: hidden;
    }
    .no-table-header thead {
        display: none;
    }

    .table-td-padding td {
        padding: 10px 2px !important;
        border: 0px !important;
    }

    .mw-150 {
        max-width: 150px;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    tr.active td {
        background-color: rgba(33, 150, 243, 0.18) !important;
    } 
</style>