<script setup>
    import {onMounted,ref,computed } from 'vue';
    import Fetch from '../classes/Fetch';
    import Cookie from '../classes/Cookie';
    import DateTime from '../classes/DateTime';
    import App from '../classes/App';
    import Header from "./Header.vue";
    import { useRoute,useRouter } from 'vue-router';

    var prenom=ref(null),nom=ref(null),login=ref(null),password=ref(null),droit=ref(0),telephone=ref(null),email=ref(null),countryName=ref(null),country=ref(null),langName=ref(null),lang=ref(null),infoUser=ref({}),page="editdevice",token=Cookie.getCookie("token"),route = useRoute(),router= useRouter();
    onMounted(() => {
        if (!token) {
            router.push({
                name: 'Login',
                // query: { kisko:2  },
            })
            return
        }
        Fetch.post("/getUser",{token:token}).then((data)=>{
            if (data) {
                prenom.value=data.name.prenom,nom.value=data.name.nom,login.value=data.login,email.value=data.email,droit.value=data.droit,telephone.value=data.telephone,lang.value=data.lang,langName.value=data.langName,country.value=data.country,countryName.value=data.countryName;
            }
        }).catch((err)=>{console.log(err);})
    });
   function saveUser() {
    var data={name:{prenom:prenom.value??undefined,nom:nom.value??undefined},login:login.value??undefined,password:password.value??undefined,email:email.value??undefined,telephone:telephone.value??undefined,droit:droit.value??undefined,lang:lang.value??undefined,langName:langName.value??undefined,countryName:countryName.value??undefined,country:country.value??undefined,};
    console.log(data);
    Fetch.post("/saveUser",{data:data,token:token}).then((data)=>{
       console.log(data);
    }).catch((err)=>{console.log(err);})
   }
</script>
<template>
<div>
    <Header :infoUser="infoUser" :page="page"></Header>
    <div class="container" style="margin-top: 100px;">
        <form>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="validationServer01">Prénom</label>
                    <input type="text" class="form-control is-valid" id="validationServer01" placeholder="Prénom" v-model="prenom" required>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServer02">Nom</label>
                    <input type="text" class="form-control is-valid" id="validationServer02" placeholder="Nom" v-model="nom" required>
               
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServerUsername">Email</label>
                    <input type="text" class="form-control is-invalid" id="validationServerUsername" placeholder="Email" v-model="email" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="validationServer03">Téléphone</label>
                <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Numero de téléphone" v-model="telephone" required>
               
                </div>
                <div class="col-md-3 mb-3">
                <label for="validationServer04">Droit</label>
                <input type="text" class="form-control is-invalid" id="validationServer04" placeholder="Imei" v-model="droit" required>
              
                </div>
                <div class="col-md-3 mb-3">
                <label for="validationServer05">Mot de passe</label>
                <input type="password" class="form-control is-invalid" id="validationServer05" placeholder="Mot de passe" v-model="password" required>
                
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="validationServer03">Login</label>
                <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Login" v-model="login" required>
               
                </div>
                <div class="col-md-3 mb-3">
                <label for="validationServer04">Code du Pays</label>
                <input type="text" class="form-control is-invalid" id="validationServer04" placeholder="Code du pays" v-model="country" required>
              
                </div>
                <div class="col-md-3 mb-3">
                    <label for="validationServer05">Code de la Langue</label>
                    <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Code de la langue" v-model="lang" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-8 mb-3">
                    <label for="validationServer103">Nom du pays </label>
                    <input type="text" class="form-control is-invalid" id="validationServer103" placeholder="Nom du pays" v-model="countryName" required>
                
                </div>
                <div class="col-md-4 mb-3">
                    <label for="validationServer105">Nom de la langue</label>
                    <input type="text" class="form-control is-invalid" id="validationServer105" placeholder="Nom de la langue" v-model="langName" required>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-10">
                    <!-- <label for="validationServer05">Consommation</label>
                    <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="model du Véhicule" v-model="consommation" required> -->
                </div>
                <div class="col-md-2 mb-3">
                    <button class="btn btn-primary" @click="saveUser()" type="button">Submit form</button>
                </div>
            </div>
        </form>
        
    </div>
</div>
</template>
