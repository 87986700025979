<template>
<div :class="'signalWrapper q'+level">
  <div class="bar s1"></div>
  <div class="bar s2"></div>
  <div class="bar s3"></div>
  <div class="bar s4"></div>
  <div class="bar s5"></div>
</div>
</template>

<script setup>
import { computed,ref,onMounted } from "vue";
var props=defineProps({level:Number});
var signal=props.level;
const color= signal >2? "green":"orange";
const emit=defineEmits(["increment"]);
    let count= ref(0);
    function handleclick(){
        emit("increment");
        count.value++;
    }
</script>
<style >
body .signalWrapper {
  height: 20px;
  display: flex;
  width: 23px;
  justify-content: space-evenly;
  align-items: flex-end;
}
body .signalWrapper .bar {
  height: 100%;
  width: 3px;
  display: block;
  border-radius: 2px;
  margin: 0 1px;
}
body .signalWrapper .bar.s1 {
  height: 20%;
}
body .signalWrapper .bar.s2 {
  height: 40%;
}
body .signalWrapper .bar.s3 {
  height: 60%;
}
body .signalWrapper .bar.s4 {
  height: 80%;
}
body .signalWrapper.q1 .bar {
  background: red;
}
body .signalWrapper.q1 .bar:not(.s1) {
  background: grey;
}
body .signalWrapper.q2 .bar {
  background: orange;
}
body .signalWrapper.q2 .bar:not(.s1):not(.s2) {
  background: grey;
}
body .signalWrapper.q3 .bar {
  background: orange;
}
body .signalWrapper.q3 .bar:not(.s1):not(.s2):not(.s3) {
  background: grey;
}
body .signalWrapper.q4 .bar {
  background: green;
}
body .signalWrapper.q4 .bar:not(.s1):not(.s2):not(.s3):not(.s4) {
  background: grey;
}
body .signalWrapper.q5 .bar {
  background: green;
}  
</style>