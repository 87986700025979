class App{
    constructor(){
    }
    isMobile(){
        return navigator.userAgent.toLowerCase().search("mobile")>0;
    }
    getAlarmes(){
        
    }
    alarmsToDisplay=[1,2,17,18,19,20,22,25,26,32,33,35,38,39,40,41,42,43,48,49,50,51,52,53,55];
    getAlarmesNames() {
    return {1:"SOS",2:"SOS inactif",3:"Contact établi(ACC ON)",4:"Contact coupé(ACC OFF)",17:"Batterie Véhicule faible",18:"Alimentation externe coupé",19:"Alimentation externe établie",20:"Batterie interne faible",22:"Excès de vitesse",
        23:"Entré en mode veille",24:"Sortie de mode veille",25:"Sortie Zone",26:"Entré Zone",27:"Perte de signal gps",28:"Signal gps récupéré",29:"Changement de cap",31:"Battement de coeur",32:"Allumage de la balise",33:"Extinction de la balise",35:"Remorquage",
        36:"Arrêt de mouvement",37:"Commence à bouger",38:"Au ralenti",39:"Accélération brutale",40:"Freinage brutal",41:"Virage serré",42:"Impact",48:"Vol de carburant",
        49:"Niveau réservoir bas",50:"Brouillage GSM",51:"Sortie du brouillage GSM",52:"Vol",53:"Identification chauffeur",55:"Démontage" };
    }
}
export default new App();