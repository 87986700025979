<template>
<table id="rapportActivityTable" class="display table" style="width:100%">
    <thead>
        <tr>
            <th>Etat</th>
            <th>Date</th>
            <th>Debut</th>
            <th>Fin</th>
            <!-- <th>Moteur</th> -->
            <th>Durée</th>
            <th class="adresse">Adresse Départ</th>
            <th class="adresse">Adresse arrivée</th>
            <th class="cellkilo">Km</th>
            <th>Vit.Max</th>
            <th>Vit.Moy</th>
            <th class="printHide">Actions</th>
        </tr>
    </thead>
    <tbody>
        <template v-for="(point, index) in historyData" :key="index">
            <tr :class="'htd_'+point.status">
                <td>{{point.status==1?"Mouvement":point.status==2?"Statique":"Inactive"}}</td>
                <td> {{moment(point.date).format("DD/MM/YYYY")}}</td>
                <td> {{moment(point.date).format("HH:mm:ss")}}</td>
                <td> {{moment(point.fin).format("HH:mm:ss")}}</td>
                <td> {{point.duree}}</td>
                <td class="adresse" :title="point.adresse" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:120px;cursor:pointer;"> {{ point.adresse}} </td>
                <td class="adresse" :title="point.adresse_fin?point.adresse_fin:''" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:120px;cursor:pointer;">{{ point.adresse_fin?point.adresse_fin:"---"}}</td>
                <td class="cellkilo"> {{point.distance}} Kms</td>
                <td>{{point.status==1?point.maxSpeed:0}}Km/h</td>
                <td>{{point.status==1?parseInt(point.distance/(point.diff/3600)):0}}Km/h</td>
                <td class="printHide">  <button class="btn btn-success btn-sm" @click="handleclick(point.date,point.fin,point.distance)"> Détail</button> </td>
            </tr>
        </template>
    </tbody>
</table>
</template>

<script setup>
import moment from 'moment'
const emit=defineEmits(["showTrajet"]);
var props=defineProps({historyData:{}});
function handleclick(debut,fin,distance){
    emit("showTrajet",[debut,fin,distance]);
}
</script>